import React from "react";
import { Button } from "reactstrap";
import Autocomplete from "../Common/Autocomplete";
import MapAddress from "../Common/MapAddress";

const emailDomains = ["@gmail.com", "@yahoo.com", "@hotmail.com"];

export default function ContactInformation({
  individual,
  headOfFamily,
  updateServerParams,
  errorFields,
  emailPrefix,
  setEmailPrefix,
  emailDomain,
  setEmailDomain,
  manualAddress,
  sehirData,
  ilceData,
  setIlceData,
  mahalleData,
  getIlceData,
  getMahalleData,
  setMahalleData,
  city,
  setCity,
  district,
  setDistrict,
  setNeighborhood,
  street,
  setStreet,
  building,
  setBuilding,
  floor,
  setFloor,
  setShowMap,
  banksData,
  handleInputForTR,
  handleKeyPressForTR,
  warningMessages,
  setWarningMessages,
  selectedFamily,
  showMap,
  handleLocationChange,
  neighborhood,
  t,
}) {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {/* Mobile Number */}
          <div className="mb-4">
            <label>
              <strong>{t("Mobile Number")}</strong>
              <span style={{ color: "red" }}> *</span>
            </label>
            <input
              className="form-control"
              placeholder={t("Add mobile number")}
              type="number"
              inputMode="numeric"
              pattern="\d*"
              min="0"
              maxLength={11}
              value={individual.mobile_number || headOfFamily?.mobile_number}
              onChange={(e) => {
                if (e.target.value.length <= 11) {
                  updateServerParams(e.target.value, "mobile_number");
                }
              }}
              style={{
                border: errorFields.includes("mobile_number")
                  ? "1px solid red"
                  : "1px solid #ced4da",
              }}
            />
          </div>

          {/* Email */}
          <div className="mb-4">
            <label>
              <strong>{t("E-mail")}</strong>
            </label>
            <div className="d-flex">
              <input
                className="form-control me-2"
                type="text"
                placeholder={t("Enter email prefix")}
                value={emailPrefix}
                onChange={(e) => {
                  const prefix = e.target.value;
                  setEmailPrefix(prefix);
                  updateServerParams(`${prefix}${emailDomain}`, "email");
                }}
              />
              <select
                className="form-select"
                value={emailDomain}
                onChange={(e) => {
                  const domain = e.target.value;
                  setEmailDomain(domain);
                  updateServerParams(`${emailPrefix}${domain}`, "email");
                }}
              >
                <option value="">{t("Select email provider")}</option>
                {emailDomains?.map((domain) => (
                  <option key={domain} value={domain}>
                    {domain}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Full Email */}
          <div className="mb-4 ">
            <label>
              <strong>{t("Full E-mail")}</strong>
            </label>
            <input
              className="form-control"
              type="text"
              value={
                individual.email ||
                headOfFamily?.email ||
                `${emailPrefix}${emailDomain}` ||
                ""
              }
              readOnly
            />
          </div>

          {/* Address Field */}
          <div className="mb-4 form-address">
            <label>
              <strong>{t("Address")}</strong>
              <span style={{ color: "red" }}> *</span>
            </label>
            <div className="d-flex">
              {!manualAddress ? (
                <input
                  className="form-control"
                  placeholder={t("Add address")}
                  value={individual.address || headOfFamily?.address}
                  onChange={(e) =>
                    updateServerParams(e.target.value, "address")
                  }
                />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col-6">
                      <Autocomplete
                        name={t("City")}
                        searchParam="name"
                        searchApi={false}
                        placeholder={t("Select city")}
                        list={sehirData}
                        selectedObject={(value) => {
                          setCity(value);
                          getIlceData(value, setIlceData);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6">
                      <Autocomplete
                        name={t("District")}
                        searchParam="name"
                        searchApi={false}
                        placeholder={t("Select district")}
                        list={ilceData}
                        selectedObject={(value) => {
                          setDistrict(value);
                          getMahalleData(value, setMahalleData);
                        }}
                        className="form-control"
                        disabled={!city}
                        hideOverlay={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      <Autocomplete
                        name={t("Neighborhood")}
                        searchParam="name"
                        searchApi={false}
                        placeholder={t("Select neighborhood")}
                        list={mahalleData}
                        selectedObject={(value) =>
                          setNeighborhood(value ? value.mahalle_title : "")
                        }
                        className="form-control"
                        disabled={!district}
                        hideOverlay={true}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        placeholder={t("Enter street")}
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-control"
                        placeholder={t("Enter building")}
                        value={building}
                        onChange={(e) => setBuilding(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        placeholder={t("Enter floor")}
                        value={floor}
                        onChange={(e) => setFloor(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}
              <Button
                className="btn-elegant"
                onClick={() => {
                  setShowMap(true);
                  setTimeout(() => {
                    document
                      .querySelector(".map-address")
                      .scrollIntoView({ behavior: "smooth" });
                  }, 100);
                }}
              >
                {t("Map/manual")}
              </Button>
            </div>
          </div>
        </div>


        {/*The second day*/}
        <div className="col-md-6">
          {/* Bank Name */}
          <div className="mb-4">
            <label>
              <strong>{t("Bank Name")}</strong>
            </label>
            <Autocomplete
              name={t("BankName")}
              searchParam="name"
              placeholder={
                individual.bank_name ||
                headOfFamily?.bank_name ||
                t("Select Bank Name")
              }
              searchApi={false}
              basePlaceholder={t("Select Bank Name")}
              list={banksData.banks?.map((bank) => ({
                name: bank,
              }))}
              selectedObject={(value) => {
                updateServerParams(value?.name, "bank_name");
              }}
              hideOverlay={true}
            />
          </div>

          {/* Account Holder Name */}
          <div className="mb-4">
            <label>
              <strong>{t("Account Holder Name")}</strong>
            </label>
            <input
              className="form-control"
              placeholder={t("Enter account holder name")}
              value={
                individual.account_holder_name ||
                headOfFamily?.account_holder_name ||
                ""
              }
              onChange={(e) => handleInputForTR(e, "account_holder_name")}
              onKeyPress={(e) => handleKeyPressForTR(e, "account_holder_name")}
              style={{ textTransform: "uppercase" }}
            />
            {warningMessages.account_holder_name && (
              <div className="alert-warning">
                {warningMessages.account_holder_name}
              </div>
            )}
          </div>

          {/* IBAN */}
          <div className="mb-4">
            <label>
              <strong>{t("IBAN")}</strong>
              <span style={{ color: "red" }}> *</span>
            </label>
            <input
              className="form-control"
              placeholder={t("Enter IBAN")}
              value={individual.iban || headOfFamily?.iban || ""}
              onChange={(e) => {
                let ibanValue = e.target.value.toUpperCase();

                const arabicToEnglishMap = {
                  "٠": "0",
                  "١": "1",
                  "٢": "2",
                  "٣": "3",
                  "٤": "4",
                  "٥": "5",
                  "٦": "6",
                  "٧": "7",
                  "٨": "8",
                  "٩": "9",
                };
                ibanValue = ibanValue.replace(
                  /[٠-٩]/g,
                  (char) => arabicToEnglishMap[char]
                );

                if (!ibanValue.startsWith("TR")) {
                  ibanValue = `TR${ibanValue}`;
                }

                ibanValue = "TR" + ibanValue.slice(2).replace(/[^0-9]/g, "");

                if (ibanValue.length > 26) {
                  setWarningMessages((prevMessages) => ({
                    ...prevMessages,
                    iban: t("IBAN cannot exceed 26 characters."),
                  }));
                  return;
                }

                if (ibanValue.length < 26) {
                  setWarningMessages((prevMessages) => ({
                    ...prevMessages,
                    iban: t("IBAN must be exactly 26 characters."),
                  }));
                } else {
                  setWarningMessages((prevMessages) => ({
                    ...prevMessages,
                    iban: "",
                  }));
                }

                updateServerParams(ibanValue, "iban");
              }}
            />
            {warningMessages.iban && (
              <div className="alert-warning">{warningMessages.iban}</div>
            )}
          </div>

          {/* Account Holder */}
          <div className="mb-4">
            <label>
              <strong>{t("Account Holder")}</strong>
            </label>
            <select
              className="form-select"
              value={
                individual.account_holder_type ||
                headOfFamily?.account_holder_type ||
                ""
              }
              onChange={(e) => {
                const selectedType = e.target.value;
                updateServerParams(selectedType, "account_holder_type");
                if (selectedType === "family_member") {
                  updateServerParams("", "custom_account_holder_name");
                } else if (selectedType === "custom") {
                  updateServerParams(null, "family_account_holder_id");
                }
              }}
            >
              <option value="">{t("Select Account Holder Type")}</option>
              <option value="family_member">{t("Family Member")}</option>
              <option value="custom">{t("Custom")}</option>
            </select>
          </div>

          {/* Conditional Fields */}
          {(individual.account_holder_type ||
            headOfFamily?.account_holder_type) === "family_member" && (
            <div className="mb-4">
              <label>
                <strong>{t("Select Family Member")}</strong>
              </label>
              <Autocomplete
                name={t("FamilyMember")}
                searchParam="name"
                placeholder={t("Select a Family Member")}
                list={selectedFamily?.individuals || []}
                selectedObject={(value) => {
                  updateServerParams(value?.id, "family_account_holder_id");
                }}
                hideOverlay={true}
              />
            </div>
          )}

          {(individual.account_holder_type ||
            headOfFamily?.account_holder_type) === "custom" && (
            <div className="mb-4">
              <label>
                <strong>
                  {t(
                    "Enter kinship"
                  )}
                </strong>
              </label>
              <input
                className="form-control"
                placeholder={t("Enter kinship")}
                value={
                  individual.custom_account_holder_name ||
                  headOfFamily?.custom_account_holder_name ||
                  ""
                }
                onChange={(e) =>
                  updateServerParams(
                    e.target.value,
                    "custom_account_holder_name"
                  )
                }
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[\d\u0660-\u0669\u06F0-\u06F9]/g, "");
                }}
              />
            </div>
          )}
        </div>
      </div>

      {/* Map Address */}
      <input
        className="form-control ms-3"
        placeholder={t("Add location")}
        value={individual.location || headOfFamily?.location}
        hidden={true}
        onChange={(e) => {
          updateServerParams(e.target.value, "location");
        }}
      />
      {/* Family | THIS FIELD IS HIDDEN TEMPORARILY */}
      {/* <div className="row mb-4">
        <div className="col-2 align-content-center">
            <p className="m-0"><strong>Family</strong></p>
        </div>
        <div className="col-10 ">
            <Autocomplete
            name="Family"
            searchParam="title"
            list={familyList}
            placeholder={selectedFamily.title || "Select a family"}
            selectedObject={(value) => {
                updateServerParams(value, "family");
                setSelectedFamily(value);
            }}
            />
        </div>
        </div> */}

      {/* Location */}
      {/* <div className="row mb-4">
        <div className="col-2 align-content-center">
            <p className="m-0">
            <strong>{t("Location")}</strong>
            </p>
        </div>
        <div className="col-6 d-flex">
            <Button
            color="primary"
            className="text-nowrap"
            onClick={() => {
                getLocation();
            }}
            >
            {locationLoading ? t("Loading...") : t("Find Location")}
            </Button>
            <input
            className="form-control ms-3"
            placeholder={t("Add location")}
            value={individual.location}
            onChange={(e) => {
                updateServerParams(e.target.value, "location");
            }}
            />
        </div>
        </div> */}
      <input
        className="form-control ms-3"
        placeholder={t("Add location")}
        value={individual.location || headOfFamily?.location}
        hidden={true}
        onChange={(e) => {
          updateServerParams(e.target.value, "location");
        }}
      />
      {/* Family | THIS FIELD IS HIDDEN TEMPORARILY */}
      {/* <div className="row mb-4">
        <div className="col-2 align-content-center">
            <p className="m-0"><strong>Family</strong></p>
        </div>
        <div className="col-10 ">
            <Autocomplete
            name="Family"
            searchParam="title"
            list={familyList}
            placeholder={selectedFamily.title || "Select a family"}
            selectedObject={(value) => {
                updateServerParams(value, "family");
                setSelectedFamily(value);
            }}
            />
        </div>
        </div> */}
      <div
        className="map-address"
        style={{
          display: showMap ? "block" : "none",
          textAlign: "center",
        }}
      >
        <MapAddress
          apiKey="AIzaSyBGXK2AgiTATdXXSL4rGi1gCSYx8EOIDrw"
          defaultCenter={{ lat: 40.73061, lng: -73.935242 }}
          onLocationChange={handleLocationChange}
          sehirData={sehirData}
          ilceData={ilceData}
          mahalleData={mahalleData}
          city={city}
          district={district}
          neighborhood={neighborhood}
          setCity={setCity}
          setDistrict={setDistrict}
          setNeighborhood={setNeighborhood}
          getIlceData={getIlceData}
          getMahalleData={getMahalleData}
          setIlceData={setIlceData}
          setMahalleData={setMahalleData}
        />
        <Button
          color="danger"
          className="mt-2"
          onClick={() => {
            setShowMap(false);
            setTimeout(() => {
              document
                .querySelector(".form-address")
                .scrollIntoView({ behavior: "smooth" });
            }, 100);
          }}
        >
          {t("Close Map")}
        </Button>
      </div>
    </>
  );
}
