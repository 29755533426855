import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Autocomplete from "components/Common/Autocomplete";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

//flatpickr
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import CalendarTranslations from "../../Utility/Functions/CalendarTranslations";

const VisitInformation = ({
  familyid,
  t,
  visit,
  isEmployee,
  volunteersList,
  familyList,
  individualList,
  readOnly,
  updateVisitData,
  getVolunteers,
  getFamilies,
  id,
  saveVisit,
  handleShow,
  requestStatues,
  showModal,
  handleClose,
  updateSupportPayload,
  createSupport,
  headOfFamily,
  i18n,
  calendarTranslations = CalendarTranslations(),
}) => {
  return (
    <Container fluid>

      <div className="form-page-container">
        {/* Volunteer */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Volunteer")}</strong>
            </p>
          </div>
          <div
            className={isEmployee ? "col-md-8 col-12 mb-1" : "col-md-10 col-12"}
          >
            {isEmployee && visit.volunteer_name ? (
              <Autocomplete
                name="Volunteer"
                searchParam="name"
                searchMethod={getVolunteers}
                placeholder={visit.volunteer_name}
                list={volunteersList.map((item) => item.individual)}
                selectedObject={(value) => {
                  updateVisitData(value?.id, "volunteer");
                }}
              />
            ) : (
              <input
                className="form-control"
                value={visit.volunteer_name}
                readOnly
              />
            )}
          </div>
          {isEmployee ? (
            <div className="col-md-2 col-12  d-flex align-items-center">
              <Link
                to="/volunteer/new"
                className="btn btn-primary w-100"
                color="primary"
              >
                <i className="fas fa-plus-circle me-1"></i>{" "}
                {t("Create Volunteer")}
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Family */}

        {familyid && (
          <div className="row mb-4">
            <div className="col-md-2 col-12 align-content-center">
              <p className="m-0">
                <strong>{t("Family")}</strong>
              </p>
            </div>
            <div className="col-md-10 col-12">
              <div className="d-flex w-100">
                <div className="w-100 me-2 ">
                  {isEmployee && !readOnly ? (
                    <Autocomplete
                      name="Family"
                      searchParam="title"
                      searchMethod={getFamilies}
                      placeholder={visit.family?.title || t("Select Family")}
                      list={familyList}
                      selectedObject={(value) => {
                        updateVisitData(value, "family_id");
                      }}
                    />
                  ) : (
                    <input
                      className="form-control"
                      value={visit.family?.title}
                      readOnly
                    />
                  )}
                </div>
                {!readOnly && (
                  <Link
                    to={`/family/${visit.family?.id}?visit=${id}`}
                    state={visit.family}
                    className="btn btn-primary me-2"
                    color="primary"
                  >
                    <i className="fas fa-pen"></i>
                  </Link>
                )}
                {isEmployee && !readOnly ? (
                  <Link
                    to="/individual/new"
                    className="btn btn-primary"
                    color="primary"
                  >
                    <i className="fas fa-plus-circle"></i>
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Individual */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Individual")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12 ">
            {isEmployee && !readOnly ? (
              <Autocomplete
                name="Individual"
                searchParam="name"
                searchApi={false}
                placeholder={visit.individual?.name || t("Select Individual")}
                list={individualList}
                selectedObject={(value) => {
                  updateVisitData(value?.id, "individual");
                }}
              />
            ) : (
              <input
                className="form-control"
                value={visit.individual?.name || t("No Individual Selected")}
                readOnly
              />
            )}
          </div>
        </div>

        {/* Visit Requester */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Visit Requester")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            <input
              className="form-control"
              value={visit.visit_requester_name || t("Requester Not Available")}
              readOnly
            />
          </div>
        </div>

        {/* Visit Date */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Visit Date")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            {visit && (
              <Flatpickr
                className="form-control d-block"
                placeholder={t("Select Date")}
                options={{
                  mode: "single",
                  dateFormat: "Y-m-d",
                  defaultDate: visit.visit_date
                    ? new Date(visit.visit_date).toISOString().split("T")[0]
                    : null,
                  locale: {
                    weekdays: {
                      shorthand: calendarTranslations.shortDays,
                      longhand: calendarTranslations.longhand,
                    },
                    months: {
                      shorthand: calendarTranslations.months,
                      longhand: calendarTranslations.months,
                    },
                  },
                }}
                onChange={(selectedDates, dateStr) => {
                  updateVisitData(dateStr, "visit_date");
                }}
              />
            )}
          </div>
        </div>

        {/* Visit Purpose */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Visit Purpose")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            <input
              className="form-control"
              placeholder={t("Enter Visit Purpose")}
              value={visit.visit_purpose}
              {...(!isEmployee ? { readOnly: true } : {})}
              onChange={(e) => {
                updateVisitData(e.target.value, "visit_purpose");
              }}
              readOnly={!isEmployee || readOnly}
            />
          </div>
        </div>

        {/* Visit Duration */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Visit Duration (Min 5 minutes)")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            <input
              className="form-control"
              type="number"
              value={visit.duration}
              min="5"
              onChange={(e) => {
                updateVisitData(Number(e.target.value), "duration");
              }}
              readOnly={readOnly}
            />
          </div>
        </div>

        {/* Visit Status */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Visit Status")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            {!readOnly ? (
              <select
                className="form-control form-select"
                value={t(visit.visit_status)}
                onChange={(value) => {
                  updateVisitData(value.target.value, "visit_status");
                }}
              >
                {/* <option value="requested">{t("Requested")}</option> */}

                <option value="pending">{t("Pending")}</option>
                <option value="draft">{t("Draft")}</option>
                <option value="completed">{t("Completed")}</option>
                <option value="cancelled">{t("cancelled")}</option>
              </select>
            ) : (
              <input
                className="form-control"
                value={t(visit.visit_status)}
                readOnly
              />
            )}
          </div>
        </div>

        {/* Volunteer Notes */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Volunteer Notes")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            <textarea
              id="notes"
              name="notes"
              className="form-control"
              maxlength="225"
              rows="2"
              placeholder={t("Volunteer Notes")}
              value={visit.volunteer_notes}
              onChange={(value) => {
                updateVisitData(value.target.value, "volunteer_notes");
              }}
              readOnly={
                isEmployee || (visit.visit_status !== "draft" && !isEmployee)
              }
            ></textarea>
          </div>
        </div>

        {/* Visit Notes */}
        <div className="row mb-4">
          <div className="col-md-2 col-12 align-content-center">
            <p className="m-0">
              <strong>{t("Notes")}</strong>
            </p>
          </div>
          <div className="col-md-10 col-12">
            <textarea
              id="notes"
              name="notes"
              className="form-control"
              maxlength="225"
              rows="5"
              placeholder={t("Add notes about the visit")}
              value={visit.visit_notes}
              onChange={(value) => {
                updateVisitData(value.target.value, "visit_notes");
              }}
              {...(!isEmployee ? { readOnly: true } : {})}
              readOnly={
                (!isEmployee && visit.visit_status !== "draft") ||
                (isEmployee && visit.visit_status !== "draft" && readOnly)
              }
            ></textarea>
          </div>
        </div>

        {/* Actions */}

        <div className="d-flex align-items-center">
          <Button
            color="primary"
            onClick={() => {
              saveVisit();
            }}
            className="me-2"
            disabled={readOnly && !isEmployee}
          >
            {t("Save")}
          </Button>
          {!isEmployee ? (
            <Button
              variant="primary"
              onClick={handleShow}
              disabled={
                visit?.visit_status == "cancelled" ||
                visit?.visit_status == "completed"
              }
            >
              {t("Suggest Support")}
            </Button>
          ) : (
            <Link
              to={`/create-support?volunteerid=${visit?.volunteer_id}&familyid=${visit?.family_id}`}
              state={visit.id}
              className={`btn btn-secondary ms-2 ${visit?.visit_status == "cancelled" ? "disabled" : ""
                }`}
            >
              {t("Create Support")}
            </Link>
          )}
        </div>
        {requestStatues && (
          <div class={`mt-3 alert alert-${requestStatues.status}`} role="alert">
            {requestStatues.text}
          </div>
        )}
      </div>

      {/* Support Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Support Suggestion")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4">
            <div className="col-md-2 col-12 align-content-center">
              <p className="m-0">
                <strong>{t("Support Description")}</strong>
              </p>
            </div>
            <div className="col-md-10 col-12">
              <textarea
                id="notes"
                name="notes"
                className="form-control"
                maxlength="225"
                rows="5"
                placeholder={t("Add notes about the support")}
                onChange={(value) => {
                  updateSupportPayload(value.target.value, "volunteer_notes");
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button variant="primary" onClick={createSupport}>
            {t("Send")}
          </Button>
        </Modal.Footer>
      </Modal>

      {visit.support?.length ? (
        <div className="form-page-container mt-3">
          <h6 className="page-title mb-3">
            {isEmployee ? t("Supports") : t("Support Suggestion")}
          </h6>
          {/* Supports Table */}
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="bg-dark">
                <tr>
                  <th scope="col">{t("Support Type")}</th>
                  <th scope="col">{t("Individual")}</th>
                  <th scope="col">{t("Family")}</th>
                  <th scope="col">{t("Volunteer")}</th>
                  <th scope="col">{t("Status")}</th>
                  {isEmployee && (
                    <th scope="col" className="text-center">
                      {t("Action")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {visit.support.map((item, index) => (
                  <tr key={index}>
                    <td>{item.support_type?.name}</td>
                    <td>{item.individual?.name}</td>
                    <td>{item.family?.title}</td>
                    <td>{item.volunteer.name}</td>
                    <td>
                      {t(item.status)}{" "}
                      {item.status === "approved" ? (
                        <i className="fas fa-check-circle text-success"></i>
                      ) : item.status === "pending" ? (
                        <i className="fas fa-clock text-warning"></i>
                      ) : (
                        <i className="fas fa-times-circle text-danger"></i>
                      )}
                    </td>
                    {isEmployee && (
                      <td className="text-center">
                        <Link
                          to={`/support/${item.id}?visit=${id}`}
                          className="btn btn-primary"
                        >
                          {t("Details")}
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default VisitInformation;
