import instance from "base_url";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { MEDIA_URL } from "configs";
import Emergency from "../Emergency/Emergency";
// TODO: import Treearchive from "./treearchive";
import { Tooltip } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import FamilyTables component
import FamilyTables from "components/family/FamilyCommon/FamilyTables";
import { getFamily } from "pages/Utility/Functions/familyTables";

export default function FamilyDetails() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 1);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [family, setFamily] = useState({});
  const [sending, setSending] = useState(false);
  const { t, i18n } = useTranslation();
  const visitId = new URLSearchParams(window.location.search).get("visit");
  const [waterBill, setWaterBill] = useState(null);
  const [gasBill, setGasBill] = useState(null);
  const [electricityBill, setElectricityBill] = useState(null);
  const [internetBill, setInternetBill] = useState(null);
  const [rent, setRent] = useState(null);
  const [emergencies, setEmergencies] = useState([]);
  const { id } = useParams();
  const rtlDirection = i18n.language === "ar" ? "rtl" : "ltr";

  const hasPendingEmergency = () =>
    emergencies.filter((item) =>
      ["pending", "processing"].includes(item.status)
    ).length == 0;

  // Tab toggle function
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const tabOrder = i18n.language === "ar" ? [4, 3, 2, 1] : [1, 2, 3, 4];

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state?.activeTab]);

  const checkMissingFields = (
    isDraft,
    waterBill,
    gasBill,
    electricityBill,
    internetBill,
    rent
  ) => {
    let missingFields = [];
    if (isDraft) missingFields.push(t("Draft Individual"));
    if (!waterBill || waterBill.amount === 0)
      missingFields.push(t("Water Bill"));
    if (!gasBill || gasBill.amount === 0) missingFields.push(t("Gas Bill"));
    if (!electricityBill || electricityBill.amount === 0)
      missingFields.push(t("Electricity Bill"));
    if (!internetBill || internetBill.amount === 0)
      missingFields.push(t("Internet Bill"));
    if (!rent || rent.amount === 0) missingFields.push(t("Rent Amount"));

    return missingFields;
  };
  const areAllFieldsComplete = () => {
    const isDraft = family.is_draft;
    return (
      !isDraft &&
      waterBill &&
      waterBill.amount > 0 &&
      gasBill &&
      gasBill.amount > 0 &&
      electricityBill &&
      electricityBill.amount > 0 &&
      internetBill &&
      internetBill.amount > 0 &&
      rent &&
      rent.amount > 0
    );
  };

  const getFamilyEmergencySituations = async () => {
    try {
      const { data } = await instance.get(
        `/families/${id}/emergency_situations/`
      );

      console.log(data);
      setEmergencies(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getFamily(
      id,
      setFamily,
      () => {},
      setWaterBill,
      setGasBill,
      setElectricityBill,
      setInternetBill,
      () => {},
      () => {},
      setRent
    );
    getFamilyEmergencySituations();
  }, [id]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  // const [isTreearchiveOpen, setIsTreearchiveOpen] = useState(false);
  // const toggleTreearchiveModal = () => setIsTreearchiveOpen(!isTreearchiveOpen);

  // Send Tree Report
  const sendTreeReport = async () => {
    setSending(true);
    const familyId = family.id;
    try {
      const response = await instance.get(
        `/families/${familyId}/send_genogram_report/`
      );
      setSending(false);
      if (response.status === 200) {
        alert("Report sent successfully");
      } else {
        alert("Failed to send report");
      }
    } catch (e) {
      setSending(false);
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content" dir={rtlDirection}>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-9">
              <Col md={5}>
                <h6 className="page-title ms-4">{family.title}</h6>
                <div
                  style={{ display: "flex", gap: "20px", marginLeft: "15px" }}
                >
                  <p className="mb-1">
                    {t("Family ID")}: {family?.id || t("Not available")}
                  </p>
                  <p className="mb-4">
                    {t("Family Members")}: {family?.individuals?.length || 0}
                  </p>
                </div>
              </Col>

              <Col md={7}>
                <div className="d-flex justify-content-end align-items-center">
                  {/* Emergency Button */}
                  <Button
                    color="secondary"
                    id="emergencyButton"
                    onClick={() => {
                      if (areAllFieldsComplete() && hasPendingEmergency()) {
                        toggleModal();
                      } else {
                        toggleTooltip();
                      }
                    }}
                    className={`mb-4 position-relative ${
                      rtlDirection === "rtl" ? "ms-3" : "me-3"
                    }`}
                  >
                    <i
                      className={`fas fa-hands-helping ${
                        rtlDirection === "rtl" ? "ms-2" : "me-2"
                      }`}
                    ></i>
                    {t("Emergency")}
                  </Button>

                  <Tooltip
                    placement="bottom"
                    isOpen={
                      tooltipOpen &&
                      (!areAllFieldsComplete() || !hasPendingEmergency())
                    }
                    target="emergencyButton"
                    toggle={toggleTooltip}
                    fade={true}
                  >
                    {!areAllFieldsComplete()
                      ? t("Please complete the following fields:") +
                        " " +
                        checkMissingFields(
                          family.is_draft,
                          waterBill,
                          gasBill,
                          electricityBill,
                          internetBill,
                          rent
                        ).join(", ")
                      : !hasPendingEmergency()
                      ? t("There are pending or processing emergencies")
                      : null}
                  </Tooltip>

                  <Emergency
                    isOpen={isModalOpen}
                    toggle={toggleModal}
                    familyId={id}
                  />

                  {/* Manage Family */}
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      caret
                      color="primary"
                      className="mb-4 position-relative"
                    >
                      <i
                        className={`fas fa-edit  ${
                          rtlDirection === "rtl" ? "ms-2" : "me-2"
                        }`}
                      ></i>
                      {t("Manage Family")}
                    </DropdownToggle>
                    <DropdownMenu
                      className={`dropdown-menu-${
                        rtlDirection === "rtl" ? "start" : "end"
                      }`}
                      style={{
                        marginTop: "-14px",
                      }}
                    >
                      <DropdownItem>
                        <Link
                          to={`/individual/new?family=${family.id}${
                            visitId ? `&visit=${visitId}` : ""
                          }`}
                          className="text-decoration-none"
                        >
                          <i
                            className={`fas fa-user-plus  ${
                              rtlDirection === "rtl" ? "ms-2" : "me-2"
                            }`}
                          ></i>
                          {t("Add New Individual")}
                        </Link>
                      </DropdownItem>

                      <DropdownItem>
                        <Link
                          to={`/familyinfo/${family.id}`}
                          className="text-decoration-none"
                        >
                          <i
                            className={`fas fa-info-circle  ${
                              rtlDirection === "rtl" ? "ms-2" : "me-2"
                            }`}
                          ></i>
                          {t("Family Information")}
                        </Link>
                      </DropdownItem>

                      <DropdownItem>
                        <Link
                          to={`/visit/new?family=${family.id}`}
                          className="text-decoration-none"
                        >
                          <i
                            className={`fas fa-calendar-plus ${
                              rtlDirection === "rtl" ? "ms-2" : "me-2"
                            }`}
                          ></i>
                          {t("Create Visit")}
                        </Link>
                      </DropdownItem>

                      <DropdownItem>
                        <Link
                          to={`/families/${family.id}/report`}
                          className="text-decoration-none"
                        >
                          <i
                            className={`fas fa-file-alt  ${
                              rtlDirection === "rtl" ? "ms-2" : "me-2"
                            }`}
                          ></i>
                          {t("View Family Report")}
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <div
              className="form-wizard-wrapper wizard clearfix"
              style={{ marginBottom: "20px" }}
            >
              <div className="steps clearfix ">
                <ul>
                  {rtlDirection === "rtl" ? (
                    <>
                      <NavItem className={activeTab === 4 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(4)}>
                          <span className="number">4.</span>{" "}
                          {t("Emergency Details")}
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 3 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(3)}>
                          <span className="number">3.</span>{" "}
                          {t("Family Information")}
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 2 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(2)}>
                          <span className="number">2.</span>{" "}
                          {t("Family Details")}
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 1 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(1)}>
                          <span className="number">1.</span> {t("Family Tree")}
                        </NavLink>
                      </NavItem>
                    </>
                  ) : (
                    <>
                      <NavItem className={activeTab === 1 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(1)}>
                          <span className="number">1.</span> {t("Family Tree")}
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 2 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(2)}>
                          <span className="number">2.</span>{" "}
                          {t("Family Details")}
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 3 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(3)}>
                          <span className="number">3.</span>{" "}
                          {t("Family Information")}
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 4 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(4)}>
                          <span className="number">4.</span>{" "}
                          {t("Emergency Details")}
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                </ul>
              </div>
            </div>

            {/* Family  */}
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center mb-8"></Row>

                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      {/* Family Tree Button Section without Accordion */}
                      <div className="mb-3">
                        <button
                          className="btn btn-danger mb-4"
                          onClick={sendTreeReport}
                        >
                          {sending ? "sending ..." : t("Send Report to Email")}
                        </button>

                        {/* Uncomment the following block if you want to add the Tree archive button */}

                        {/* <button
                              className="btn btn-primary mb-4"
                              onClick={toggleTreearchiveModal}
                            >
                              {t("Tree archive")}
                            </button>

                            <Treearchive
                              isOpen={isTreearchiveOpen}
                              toggle={toggleTreearchiveModal}
                              familyId={id}
                            />
                            */}

                        <br />
                        <img
                          src={`${MEDIA_URL}genogram/${family.id}/${family.family_tree_image}`}
                          alt="Family Tree"
                        />
                      </div>
                    </TabPane>

                    <TabPane tabId={2}>
                      <div className="mb-4">
                        {/* Family Details */}
                        <FamilyTables
                          tableType="familyMembers"
                          id={family.id}
                          t={t}
                          legend={true}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <FamilyTables
                        tableType="familyInfo"
                        id={family.id}
                        t={t}
                      />
                    </TabPane>
                    <TabPane tabId={4}>
                      <FamilyTables
                        tableType="emergencies"
                        id={family.id}
                        t={t}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </div>
          {/* Actions */}
          <div className="actions clearfix">
            <div
              className="button-group"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div>
                <Button
                  className={activeTab === 1 ? "previous disabled" : "previous"}
                  onClick={() => toggleTab(activeTab - 1)}
                >
                  {t("Previous")}
                </Button>
              </div>
              <div>
                <Button
                  className={activeTab === 4 ? "next disabled" : "next"}
                  onClick={() => toggleTab(activeTab + 1)}
                >
                  {t("Next")}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
