import instance from "base_url";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Dropdown } from "reactstrap";
import { withTranslation, useTranslation } from "react-i18next";
import NavigationTabs from "./visitInformation/NavigationTabs";
import IndividualsInformation from "./visitInformation/individualsInformation";
import GeneralInformation from "./visitInformation/generalInformation";
import { MEDIA_URL } from "configs";
import VisitInformation from "./visitInformation/visitInformation";
import { fetchFamilyIndividuals } from "pages/Utility/Functions/familyTables";
import { Legend } from "chart.js";

function VisitDetails({ t }) {
  const { i18n } = useTranslation();
  const { state } = useLocation();
  const [family, setFamily] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const isEmployee = localStorage.getItem("userType") === "employee";
  const [visit, setVisit] = useState({});
  const [volunteersList, setVolunteersList] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [individualList, setIndividualList] = useState([]);
  const [requestStatues, setRequestStatues] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [supportPayload, setSupportPayload] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [alert, setAlert] = useState({ show: false, description: "" });
  const [loading, setLoading] = useState(true);
  const [headOfFamily, setHeadOfFamily] = useState({});
  const [redCrescent, setRedCrescent] = useState({});
  const [isRedCrescent, setIsRedCrescent] = useState("");
  const [rentAmount, setRentAmount] = useState(0);
  const [individuals, setIndividuals] = useState([]);
  const [familyId, setFamilyId] = useState(null);
  const [income, setIncome] = useState(0);
  const [totalBills, setTotalBills] = useState(0);
  const [underTwo, setUnderTwo] = useState(0);
  const [overTwo, setOverTwo] = useState(0);
  const [adults, setAdults] = useState(0);
  const [seniors, setSeniors] = useState(0);
  const [illnessSeverity, setIllnessSeverity] = useState({});
  const [disabilitySeverity, setDisabilitySeverity] = useState({});
  const [illnessDisabilitySeverity, setIllnessDisabilitySeverity] = useState({});

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const rtlDirection = i18n.language === "ar" ? "rtl" : "ltr";

  const updateSupportPayload = (value, param) => {
    const newSupportPayload = { ...supportPayload };
    newSupportPayload[param] = value;
    setSupportPayload(newSupportPayload);
    console.log("supportpayload", newSupportPayload);
  };

  // Create Support
  const createSupport = async () => {
    const payload = { ...supportPayload };
    payload.visit = id;
    try {
      const res = instance.post("/support/", payload);
      handleClose();
      setRequestStatues({
        status: "success",
        text: t("Support Suggested!"),
      });
      setIsRefresh(!isRefresh);
    } catch (e) {
      console.error(e);
    }
  };

  // Get Visit Details
  const getVisitDetails = async () => {
    try {
      const { data } = await instance.get(`/visits/${id}/`);
      data.family_id = data.family.id;
      setVisit(data);
      setFamilyId(data.family.id);
      // setIsRefresh(!isRefresh);
    } catch (e) {
      console.error(e);
    }
  };

  // get Volunteers
  const getVolunteers = async (search) => {
    try {
      let data = [];
      if (search) {
        const response = await instance.get(`/volunteers/?search=${search}`);
        data = response.data;
      } else {
        const response = await instance.get("/volunteers/");
        data = response.data;
      }
      setVolunteersList(data.results);
    } catch (e) {
      console.log(e);
    }
  };

  // get Families
  const getFamilies = async (search) => {
    try {
      let data = [];
      if (search) {
        const response = await instance.get(`/families/?search=${search}`);
        data = response.data;
      } else {
        const response = await instance.get("/families/");
        data = response.data;
      }
      setFamilyList(data.results);
    } catch (e) {
      console.log(e);
    }
  };


  // get Individuals
  // const getIndividuals = async () => {
  //   try {
  //     const { data } = await instance.get(
  //       `/families/${state.family?.id}/individuals/`
  //     );
  //     data[0].name = `${data[0].first_name} ${data[0].last_name}`;
  //     setIndividualList(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // Update server params on fields change
  const updateVisitData = (value, param) => {
    const newVisit = { ...visit };
    newVisit[param] = value;
    setVisit(newVisit);

    if (param === "family_id") {
      const familyIndividualList = [
        ...familyList.find((item) => item.id === value?.id).individuals,
      ];
      familyIndividualList.forEach((item) => {
        item.name = `${item.first_name} ${item.last_name}`;
      });
      setIndividualList(familyIndividualList);
      const newVisit = { ...visit };
      newVisit.family = value?.id;
      setVisit(newVisit);
    }
    console.log(visit.duration);
  };

  useEffect(() => {
    getVisitDetails();
    if (isEmployee) {
      getVolunteers();
    }
    getFamilies();
  }, [isRefresh]);

  useEffect(() => {
    fetchFamilyIndividuals(
      familyId,
      setIndividuals,
      setFamily,
      setHeadOfFamily,
      setRedCrescent,
      setIsRedCrescent,
      setIncome,
      setTotalBills,
      setRentAmount,
      setLoading,
      setUnderTwo,
      setOverTwo,
      setAdults,
      setSeniors,
      setIllnessSeverity,
      setDisabilitySeverity,
      setIllnessDisabilitySeverity,
    );
    if (
      visit.visit_status === "completed" ||
      visit.visit_status === "cancelled" ||
      visit.visit_status === "draft"
    ) {
      setReadOnly(true);
    }
  }, [visit]);

  const saveVisit = async () => {
    try {
      const res = await instance.put(`/visits/${id}/`, visit);
      setRequestStatues({
        status: "success",
        text: t("Visit Updated!"),
      });
      setTimeout(() => {
        setRequestStatues(null);
        navigate(-1);
      }, 2000);
    } catch (e) {
      console.error(e);
      setRequestStatues({
        status: "danger",
        text: t("An Error Occurred!"),
      });
    }
  };

  // Send Tree Report
  const [sending, setSending] = useState(false);
  const sendTreeReport = async () => {
    setSending(true);
    const familyId = family.id;
    try {
      const response = await instance.get(
        `/families/${familyId}/send_genogram_report/`
      );
      setSending(false);
      if (response.status === 200) {
        alert("Report sent successfully");
      } else {
        alert("Failed to send report");
      }
    } catch (e) {
      setSending(false);
      console.error(e);
    }
  };

  const fetchFamilyDetails = async () => {
    try {
      setLoading(true);
      const response = await instance.get(`/families/${familyId}/`);
      if (response && response.data) {
        const apiData = response.data;
        console.log("API Response:", apiData);
        setFamily(apiData);
        setIndividuals(apiData.individuals || []);
      } else {
        console.error("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (familyId) {
      fetchFamilyDetails();
    }
  }, [familyId]);

  console.log("family", family);
  console.log("familyId:", familyId);
  console.log("individuals:", individuals);

  // tabs
  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="page-content" dir={rtlDirection}>
      {alert.show && (
        <div
          className="alert alert-danger fade show"
          role="alert"
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            top: "auto",
            zIndex: 1050,
          }}
        >
          {alert.description}
        </div>
      )}
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={4}>
              <h4 className="my-2">
                {t("Visit Details")}: {visit.family?.title}
              </h4>
            </Col>
          </Row>
        </div>
        <Row>
          <NavigationTabs
            rtlDirection={rtlDirection}
            activeTab={activeTab}
            toggleTab={toggleTab}
            errorFields={[]}
            fieldsTabsMapping={{
              1: ["volunteer"],
              2: ["family"],
              3: ["individual"],
            }}
            t={t}
          />
          <Col sm="12">
            {activeTab === 1 && (
              <div>
                <IndividualsInformation
                  legend={Legend}
                  t={t}
                  individuals={individuals}
                  family={visit?.family}
                  headOfFamily={headOfFamily}
                  illnessSeverity={illnessSeverity}
                  disabilitySeverity={disabilitySeverity}
                  illnessDisabilitySeverity={illnessDisabilitySeverity}
                  underTwo={underTwo}
                  overTwo={overTwo}
                  adults={adults}
                  seniors={seniors}
                  setUnderTwo={setUnderTwo}
                  setOverTwo={setOverTwo}
                  setAdults={setAdults}
                  setSeniors={setSeniors}
                  setIllnessSeverity={setIllnessSeverity}
                  setDisabilitySeverity={setDisabilitySeverity}
                  setIllnessDisabilitySeverity={setIllnessDisabilitySeverity}
                  MEDIA_URL={MEDIA_URL}
                  id={id}
                  sendTreeReport={() => {}}
                  sending={false}
                  isTreearchiveOpen={false}
                  toggleTreearchiveModal={() => {}}
                />
              </div>
            )}
            {activeTab === 2 && (
              <div>
                <GeneralInformation
                  t={t}
                  family={visit?.family}
                  MEDIA_URL={MEDIA_URL}
                  id={id}
                  sendTreeReport={() => {}}
                  sending={false}
                  isTreearchiveOpen={false}
                  toggleTreearchiveModal={() => {}}
                  individuals={individuals}
                  headOfFamily={headOfFamily}
                  rentAmount={rentAmount}
                  isRedCrescent={isRedCrescent}
                  redCrescent={redCrescent}
                  income={income}
                  totalBills={totalBills}
                  setRentAmount={setRentAmount}
                  visibleTables={["request"]}
                />
              </div>
            )}
            {activeTab === 3 && (
              <div>
                <VisitInformation
                  t={t}
                  visit={visit}
                  isEmployee={isEmployee}
                  volunteersList={volunteersList}
                  familyList={familyList}
                  individualList={individuals}
                  readOnly={readOnly}
                  updateVisitData={updateVisitData}
                  getVolunteers={getVolunteers}
                  getFamilies={getFamilies}
                  headOfFamily={headOfFamily}
                  id={id}
                  saveVisit={saveVisit}
                  handleShow={handleShow}
                  requestStatues={requestStatues}
                  showModal={showModal}
                  handleClose={handleClose}
                  updateSupportPayload={updateSupportPayload}
                  createSupport={createSupport}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default withTranslation()(VisitDetails);
