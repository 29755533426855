import React from "react";
import { NavItem, NavLink } from "reactstrap";

const NavigationTabs = ({ rtlDirection, activeTab, toggleTab, errorFields, fieldsTabsMapping, t }) => {

  return (
    <div
      className="form-wizard-wrapper wizard clearfix"
      style={{
        marginBottom: "20px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="steps clearfix">
        <ul
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            listStyleType: "none",
            gap: "20px",
          }}
        >
          {rtlDirection === "rtl" ? (
            <>
              <NavItem className={activeTab === 1 ? "current" : ""}>
                <NavLink onClick={() => toggleTab(1)}>
                  <span
                    className="number"
                    style={{
                      border: errorFields.some((x) =>
                        fieldsTabsMapping[1].includes(x)
                      )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                      backgroundColor: errorFields.some((x) =>
                        fieldsTabsMapping[1].includes(x)
                      )
                        ? "red"
                        : "inherit",
                      color: errorFields.some((x) =>
                        fieldsTabsMapping[1].includes(x)
                      )
                        ? "white"
                        : "inherit",
                      borderRadius: "50%",
                      padding: "5px 10px",
                    }}
                  >
                    1.
                  </span>
                  <span>{t("Individuals information")}</span>
                </NavLink>
              </NavItem>

              <NavItem className={activeTab === 2 ? "current" : ""}>
                <NavLink onClick={() => toggleTab(2)}>
                  <span
                    className="number"
                    style={{
                      border: errorFields.some((x) =>
                        fieldsTabsMapping[2].includes(x)
                      )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                      backgroundColor: errorFields.some((x) =>
                        fieldsTabsMapping[2].includes(x)
                      )
                        ? "red"
                        : "inherit",
                      color: errorFields.some((x) =>
                        fieldsTabsMapping[2].includes(x)
                      )
                        ? "white"
                        : "inherit",
                      borderRadius: "50%",
                      padding: "5px 10px",
                    }}
                  >
                    2.
                  </span>
                  <span>{t("General information")}</span>
                </NavLink>
              </NavItem>

              <NavItem className={activeTab === 3 ? "current" : ""}>
                <NavLink onClick={() => toggleTab(3)}>
                  <span
                    className="number"
                    style={{
                      border: errorFields.some((x) =>
                        fieldsTabsMapping[3].includes(x)
                      )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                      backgroundColor: errorFields.some((x) =>
                        fieldsTabsMapping[3].includes(x)
                      )
                        ? "red"
                        : "inherit",
                      color: errorFields.some((x) =>
                        fieldsTabsMapping[3].includes(x)
                      )
                        ? "white"
                        : "inherit",
                      borderRadius: "50%",
                      padding: "5px 10px",
                    }}
                  >
                    3.
                  </span>
                  <span>{t("Visit information")}</span>
                </NavLink>
              </NavItem>
            </>
          ) : (
            <>
              <NavItem className={activeTab === 1 ? "current" : ""}>
                <NavLink onClick={() => toggleTab(1)}>
                  <span
                    className="number"
                    style={{
                      border: errorFields.some((x) =>
                        fieldsTabsMapping[1].includes(x)
                      )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                      backgroundColor: errorFields.some((x) =>
                        fieldsTabsMapping[1].includes(x)
                      )
                        ? "red"
                        : "inherit",
                      color: errorFields.some((x) =>
                        fieldsTabsMapping[1].includes(x)
                      )
                        ? "white"
                        : "inherit",
                      borderRadius: "50%",
                      padding: "5px 10px",
                    }}
                  >
                    1.
                  </span>
                  <span>{t("Family information")}</span>
                </NavLink>
              </NavItem>

              <NavItem className={activeTab === 2 ? "current" : ""}>
                <NavLink onClick={() => toggleTab(2)}>
                  <span
                    className="number"
                    style={{
                      border: errorFields.some((x) =>
                        fieldsTabsMapping[2].includes(x)
                      )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                      backgroundColor: errorFields.some((x) =>
                        fieldsTabsMapping[2].includes(x)
                      )
                        ? "red"
                        : "inherit",
                      color: errorFields.some((x) =>
                        fieldsTabsMapping[2].includes(x)
                      )
                        ? "white"
                        : "inherit",
                      borderRadius: "50%",
                      padding: "5px 10px",
                    }}
                  >
                    2.
                  </span>
                  <span>{t("General information")}</span>
                </NavLink>
              </NavItem>

              <NavItem className={activeTab === 3 ? "current" : ""}>
                <NavLink onClick={() => toggleTab(3)}>
                  <span
                    className="number"
                    style={{
                      border: errorFields.some((x) =>
                        fieldsTabsMapping[3].includes(x)
                      )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                      backgroundColor: errorFields.some((x) =>
                        fieldsTabsMapping[3].includes(x)
                      )
                        ? "red"
                        : "inherit",
                      color: errorFields.some((x) =>
                        fieldsTabsMapping[3].includes(x)
                      )
                        ? "white"
                        : "inherit",
                      borderRadius: "50%",
                      padding: "5px 10px",
                    }}
                  >
                    3.
                  </span>
                  <span>{t("Visit information")}</span>
                </NavLink>
              </NavItem>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavigationTabs;
