import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

const FamilyReportTables = ({
  individuals = [],
  calculateAge = () => 0,
  illnessSeverity = { 1: 0, 2: 0, 3: 0 },
  disabilitySeverity = { 1: 0, 2: 0, 3: 0 },
  illnessDisabilitySeverity = { 1: 0, 2: 0, 3: 0 },
  rentAmount = 0,
  isRedCrescent = null,
  redCrescent = null,
  setRentAmount,
  income = 0,
  totalBills = 0,
  underTwo = 0,
  overTwo = 0,
  adults = 0,
  seniors = 0,
  headOfFamily = null,
  visibleTables = [],
}) => {
  const { t } = useTranslation();

  console.log(individuals, "=-=-=-=-=-=-");

  const renderIndividualRow = (individual) => (
    <tr
      key={individual.id}
      style={{ borderColor: "#343a40" }}
    >
      <td
        style={{
          fontWeight: "bold",
          color: "#000",
          fontSize: "18px",
          borderColor: "#343a40",
        }}
      >
        {individual.id === headOfFamily?.partner_id && (
          <i
            className="fas fas fa-gem"
            style={{
              color: "pink",
              marginRight: "15px",
              fontSize: "16px",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
            }}
            title={t("Wife's")}
          ></i>
        )}
        {individual.id === headOfFamily?.id && (
          <i
          className="fas fa-crown"
          style={{
            color: "#FFD700",
            marginRight: "15px",
            fontSize: "16px",
            textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
          }}
          title={t("Head of Family")}
        ></i>
        )}
        {`${individual.first_name} ${individual.last_name}`}
      </td>

      <td
        style={{
          fontWeight: "bold",
          color: "#000",
          fontSize: "18px",
          borderColor: "#343a40",
        }}
      >
        {individual.date_of_birth ? (
          `${individual.date_of_birth} (${calculateAge(
            individual.date_of_birth
          )})`
        ) : (
          <i
            className="fas fa-ban"
            style={{ color: "red" }}
          ></i>
        )}
      </td>
      <td
        style={{
          fontWeight: "bold",
          color: "#000",
          fontSize: "18px",
          borderColor: "#343a40",
        }}
      >
        {individual.job_title ? (
          individual.job_title
        ) : (
          <i
            className="fas fa-ban"
            style={{ color: "red" }}
          ></i>
        )}
      </td>
      <td
        style={{
          fontWeight: "bold",
          color: "#000",
          fontSize: "18px",
          borderColor: "#343a40",
        }}
      >
        {individual.is_working && individual.salary ? (
          parseInt(individual.salary, 10)
        ) : (
          <i
            className="fas fa-ban"
            style={{ color: "red" }}
          ></i>
        )}
      </td>
      <td
        style={{
          fontWeight: "bold",
          color:
            individual.status === "alive"
              ? "green"
              : individual.status === "dead"
              ? "red"
              : individual.status === "lost"
              ? "gray"
              : "black",
          fontSize: "18px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <span>{t(individual.status)}</span>
        {individual.condition_type &&
          individual.condition_severity > 0 && (
            <>
              {individual.condition_type.toLowerCase() ===
                "illness" && (
                <i
                  className="fas fa-notes-medical"
                  style={{
                    color: "#ff9800",
                    fontSize: "16px",
                  }}
                  title={t("Has Medical Condition")}
                ></i>
              )}
              {individual.has_condition && individual.condition_type.toLowerCase() ===
                "disability" && (
                <i
                  className="fas fa-wheelchair"
                  style={{
                    color: "#4caf50",
                    fontSize: "16px",
                  }}
                  title={t("Has Disability")}
                ></i>
              )}
              {individual.condition_type.toLowerCase() ===
                "illness_disability" && (
                <i
                  className="fas fa-clinic-medical"
                  style={{
                    color: "#9c27b0",
                    fontSize: "16px",
                  }}
                  title={t(
                    "Has Both Medical and Disability"
                  )}
                ></i>
              )}
            </>
          )}
      </td>
    </tr>
  )

  return (
    <div>
      {/* Individuals Information Table */}
      {visibleTables.includes("individuals") && (
        <Row>
          <div className="col-12">
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "2px",
                color: "#000",
                textTransform: "uppercase",
              }}
            >
              <h4>{t("Individuals Information")}</h4>
            </div>
            <Table
              bordered
              responsive
              style={{
                borderColor: "#343a40",
                width: "95%",
                margin: "auto",
              }}
            >
              <thead>
                <tr style={{ borderColor: "#343a40" }}>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "20px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Name")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "20px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Date of Birth (Age)")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "20px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Job Title")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "20px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Salary")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "20px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Status")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {individuals.length > 0 ? (
                  <>
                    {individuals
                      // .filter((individual) => !individual.is_head_of_family)
                      .sort((a, b) => {
                        if (a.is_head_of_family) return -1;
                        if (b.is_head_of_family) return 1;
                        return 0;
                      })
                      .map((individual) => (
                        renderIndividualRow(individual)
                      ))}
                  </>
                ) : (
                  <tr style={{ borderColor: "#343a40" }}>
                    <td
                      colSpan="6"
                      className="text-center"
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontSize: "18px",
                        borderColor: "#343a40",
                      }}
                    >
                      <i className="fas fa-ban" style={{ color: "red" }}></i>{" "}
                      {t("No Data Available")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <h6
                style={{
                  color: "#555",
                  display: "inline-block",
                  marginInlineEnd: "10px",
                }}
              >
                {t("Meanings of signs")}:
              </h6>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {[
                  {
                    icon: "fas fa-gem",
                    color: "pink",
                    text: t("Wife's"),
                  },

                  {
                    icon: "fa-crown",
                    color: "#FFD700",
                    text: t("Head of Family"),
                  },
                  {
                    icon: "fa-notes-medical",
                    color: "#ff9800",
                    text: t("Medical"),
                  },
                  {
                    icon: "fa-wheelchair",
                    color: "#4caf50",
                    text: t("Disability"),
                  },
                  {
                    icon: "fa-clinic-medical",
                    color: "#9c27b0",
                    text: t("Both Medical and Disability"),
                  },
                ].map((legend, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      color: "#555",
                    }}
                  >
                    <i
                      className={`fas ${legend.icon}`}
                      style={{
                        color: legend.color,
                        marginInlineEnd: "5px",
                        fontSize: "16px",
                      }}
                    ></i>
                    <span>{legend.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Row>
      )}

      {/* Medical Condition / Disability Table */}
      {visibleTables.includes("medical") && (
        <Row>
          <Col>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "2px",
                color: "#000",
                textTransform: "uppercase",
              }}
            >
              {t("Medical Condition / Disability")}
            </div>
            <Table
              bordered
              responsive
              style={{
                borderColor: "#343a40",
                margin: "auto",
              }}
            >
              <thead>
                <tr style={{ borderColor: "#343a40" }}>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Category")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Severity 1")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Severity 2")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Severity 3")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Illness Cases")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {illnessSeverity[1]}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {illnessSeverity[2]}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {illnessSeverity[3]}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Disability Cases")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {disabilitySeverity[1]}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {disabilitySeverity[2]}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {disabilitySeverity[3]}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Illness and Disability Cases")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {illnessDisabilitySeverity[1]}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {illnessDisabilitySeverity[2]}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {illnessDisabilitySeverity[3]}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {/* Request Information Table */}
      {visibleTables.includes("request") && (
        <Row>
          <Col>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "2px",
                color: "#333",
                textTransform: "uppercase",
              }}
            >
              {t("General information")}
            </div>
            <Table
              bordered
              responsive
              style={{
                borderColor: "#343a40",

                margin: "auto",
              }}
            >
              <tbody>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Husband's Name")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {headOfFamily ? (
                      `${headOfFamily.first_name} ${headOfFamily.last_name}`
                    ) : (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Wife's Name")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {individuals.some(
                      (ind) => ind.id === headOfFamily?.partner_id
                    ) ? (
                      `${
                        individuals.find(
                          (ind) => ind.id === headOfFamily.partner_id
                        ).first_name
                      } ${
                        individuals.find(
                          (ind) => ind.id === headOfFamily.partner_id
                        ).last_name
                      }`
                    ) : (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                  </td>
                </tr>

                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Rent Amount")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {!rentAmount ? (
                          <i
                            className="fas fa-ban"
                            style={{ color: "red" }}
                          ></i>
                        ) : (
                          Math.floor(rentAmount)
                        )}
                      </span>
                      <a
                        target="_blank"
                        className="btn btn-outline-primary btn-sm"
                        href={`/familyinfo/${individuals[0]?.family}`}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>

                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Red Crescent")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {isRedCrescent === "Yes" ? (
                          <>
                            {t("Yes")} : &nbsp;
                            <span style={{ fontWeight: "bold" }}>
                              {redCrescent?.amount ? (
                                Math.floor(redCrescent.amount)
                              ) : (
                                <i
                                  className="fas fa-ban"
                                  style={{ color: "red" }}
                                ></i>
                              )}
                            </span>
                          </>
                        ) : isRedCrescent === "No" ? (
                          <>
                            {t("No")} : &nbsp;
                            <span style={{ fontWeight: "bold" }}>
                              {redCrescent?.additional_info?.reason || (
                                <i
                                  className="fas fa-ban"
                                  style={{ color: "red" }}
                                ></i>
                              )}
                            </span>
                          </>
                        ) : (
                          <i
                            className="fas fa-ban"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </span>
                      <a
                        target="_blank"
                        className="btn btn-outline-primary btn-sm"
                        href={`/familyinfo/${individuals[0]?.family}`}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Total of Bills")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{totalBills || 0}</span>
                      <a
                        target="_blank"
                        className="btn btn-outline-primary btn-sm"
                        href={`/familyinfo/${individuals[0]?.family}`}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Mobile Number")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {headOfFamily?.mobile_number ||
                          (individuals.length > 0
                            ? individuals[0].mobile_number
                            : 0)}
                      </span>
                      <a
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) => {
                          e.preventDefault();

                          const headOfFamily = individuals.find(
                            (individual) =>
                              individual.is_head_of_family === true
                          );

                          if (headOfFamily) {
                            window.open(
                              `/individual/${headOfFamily.id}?family=${headOfFamily.family}`,
                              "_blank"
                            );
                          } else if (individuals.length > 0) {
                            const fallbackIndividual = individuals[0];
                            window.open(
                              `/individual/${fallbackIndividual.id}?family=${fallbackIndividual.family}`,
                              "_blank"
                            );
                          } else {
                            alert("No individuals available!");
                          }
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Address")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      wordBreak: "break-word",
                      borderColor: "#343a40",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {headOfFamily?.address ||
                          (individuals.length > 0
                            ? individuals[0].address
                            : "0")}
                      </span>
                      <a
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) => {
                          e.preventDefault();

                          const headOfFamily = individuals.find(
                            (individual) =>
                              individual.is_head_of_family === true
                          );

                          if (headOfFamily) {
                            window.open(
                              `/individual/${headOfFamily.id}?family=${headOfFamily.family}`,
                              "_blank"
                            );
                          } else if (individuals.length > 0) {
                            const fallbackIndividual = individuals[0];
                            window.open(
                              `/individual/${fallbackIndividual.id}?family=${fallbackIndividual.family}`,
                              "_blank"
                            );
                          } else {
                            alert("No individuals available!");
                          }
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {/* Request Details Table */}
      {visibleTables.includes("requestDetails") && (
        <Row>
          <Col>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "2px",
                color: "#000",
                textTransform: "uppercase",
              }}
            >
              {t("Request Details")}
            </div>
            <Table
              bordered
              responsive
              style={{
                borderColor: "#343a40",
                margin: "auto",
              }}
            >
              <tbody>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Applicant")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {headOfFamily ? (
                      `${headOfFamily.first_name} ${headOfFamily.last_name}`
                    ) : (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                  </td>
                </tr>
                {/* <tr>
             <td style={{ fontWeight: "bold" }}>
               {t("Father's Name")}:
             </td>
             <td>
               {headOfFamily
                 ? headOfFamily.father_name || "0"
                 : individuals.length > 0
                 ? individuals.reduce(
                     (oldest, individual) => {
                       return new Date(
                         individual.date_of_birth
                       ) < new Date(oldest.date_of_birth)
                         ? individual
                         : oldest;
                     }
                   ).father_name || "0"
                 : "0"}
             </td>
           </tr>
           <tr>
             <td style={{ fontWeight: "bold" }}>
               {t("Mother's Name")}:
             </td>
             <td>
               {headOfFamily ? (
                 headOfFamily.mother_name || "0"
               ) : (
                 <i className="fas fa-ban"></i>
               )}
             </td>
           </tr> */}
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Volunteer Name")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {individuals.length > 0 ? (
                      individuals[0].volunteer_name
                    ) : (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                  </td>
                </tr>
                {/* <tr style={{ borderColor: "#343a40" }}>
             <td
               style={{
                 fontWeight: "bold",
                 color: "#333",
                 borderColor: "#343a40",
               }}
             >
               {t("Request ID")}:
             </td>
             <td
               style={{
                 fontWeight: "bold",
                 color: "#333",
                 borderColor: "#343a40",
               }}
             >
               {individuals.length > 0 ? (
                 individuals[0].last_updated_by_visit
               ) : (
                 <i className="fas fa-ban"></i>
               )}
             </td>
           </tr> */}
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Request Date")}:
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      borderColor: "#343a40",
                    }}
                  >
                    {individuals.length > 0 ? (
                      new Date(individuals[0].visit_date).toLocaleDateString(
                        "en-CA"
                      )
                    ) : (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {/* Age Categories */}
      {visibleTables.includes("ageCategories") && (
        <Row>
          <Col>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "2px",
                color: "#000",
                textTransform: "uppercase",
              }}
            >
              {t("Age Categories")}
            </div>
            <Table
              bordered
              responsive
              style={{
                borderColor: "#343a40",
                margin: "auto",
              }}
            >
              <thead>
                <tr style={{ borderColor: "#343a40" }}>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      textAlign: "left",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Category")}
                  </th>
                  <th
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "18px",
                      textAlign: "center",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Count")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Children under 2 years")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      textAlign: "center",
                      borderColor: "#343a40",
                    }}
                  >
                    {underTwo}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Children over 2 years")}
                  </td>

                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      textAlign: "center",
                      borderColor: "#343a40",
                    }}
                  >
                    {overTwo}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Adults (18 years and above)")}{" "}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      textAlign: "center",
                      borderColor: "#343a40",
                    }}
                  >
                    {adults}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Seniors (55 years and above)")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      textAlign: "center",
                      borderColor: "#343a40",
                    }}
                  >
                    {seniors}
                  </td>
                </tr>
                <tr style={{ borderColor: "#343a40" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      borderColor: "#343a40",
                    }}
                  >
                    {t("Total family members")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: "16px",
                      textAlign: "center",
                      borderColor: "#343a40",
                    }}
                  >
                    {individuals.length}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FamilyReportTables;
