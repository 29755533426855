import React from "react";
import { Button, Row, Col } from "reactstrap";

const ActiveFilters = ({
  familyStatus,
  identityPlace,
  healthCondition,
  province,
  district,
  area,
  setFamilyStatus,
  setIdentityPlace,
  setHealthCondition,
  setProvince,
  setDistrict,
  setArea,
  setSearch,
  applyFilters,
  filtersApplied,
  setFiltersApplied,
  setMaxMembers,
  setMinMembers,
  minMembers,
  maxMembers,

  t,
}) => {
  if (!filtersApplied) return null;

  const resetFilters = () => {
    setFamilyStatus("");
    setIdentityPlace("");
    setHealthCondition("");
    setProvince("");
    setDistrict("");
    setArea("");
    setSearch("");
    setFiltersApplied(false);
    applyFilters();
    setMinMembers("");
    setMaxMembers("");
  };

  const getActiveFilters = () => {
    const activeFilters = [];

    if (familyStatus) {
      activeFilters.push({
        label: `${t("Family Status")}: ${t(familyStatus)}`,
        clear: () => {
          setFamilyStatus("");
          applyFilters();
        },
      });
    }

    if (identityPlace) {
      activeFilters.push({
        label: `${t("Identity Place")}: ${identityPlace}`,
        clear: () => {
          setIdentityPlace("");
          applyFilters();
        },
      });
    }

    if (healthCondition) {
      activeFilters.push({
        label: `${t("Condition")}: ${t(healthCondition)}`,
        clear: () => {
          setHealthCondition("");
          applyFilters();
        },
      });
    }

    if (province) {
      activeFilters.push({
        label: `${t("Province")}: ${province}`,
        clear: () => {
          setProvince("");
          applyFilters();
        },
      });
    }

    if (district) {
      activeFilters.push({
        label: `${t("District")}: ${district}`,
        clear: () => {
          setDistrict("");
          applyFilters();
        },
      });
    }

    if (area) {
      activeFilters.push({
        label: `${t("Area")}: ${area}`,
        clear: () => {
          setArea("");
          applyFilters();
        },
      });
    }

    if (minMembers || maxMembers) {
      const rangeLabel = `${t("Number of Family Members")}: ${
        minMembers ? `${t("From")} ${minMembers}` : ""
      } ${maxMembers ? `${t("To")} ${maxMembers}` : ""}`.trim();
      activeFilters.push({
        label: rangeLabel,
        clear: () => {
          setMinMembers("");
          setMaxMembers("");
          applyFilters();
        },
      });
    }
    

    return activeFilters;
  };

  const filters = getActiveFilters();

  if (filters.length === 0) return null;

  return (
    <Row className="align-items-center mt-3">
      <Col md={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <h6 style={{ marginRight: "10px", fontWeight: "bold" }}>
            {t("Active Filters")}:
          </h6>
          {filters.map((filter, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#E9F5FF",
                color: "#007BFF",
                borderRadius: "5px",
                padding: "5px 10px",
                fontSize: "0.9rem",
                display: "inline-flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>{filter.label}</span>
              <i
                className="fas fa-times"
                onClick={() => {
                  console.log(`Clearing filter: ${filter.label}`);
                  filter.clear();
                  applyFilters();
                }}
                style={{
                  cursor: "pointer",
                  color: "#ff0000",
                  fontSize: "1rem",
                }}
              ></i>
            </div>
          ))}
          <Button
            color="danger"
            size="sm"
            onClick={resetFilters}
            style={{
              marginLeft: "auto",
              padding: "5px 15px",
              fontSize: "0.9rem",
              alignSelf: "flex-start",
              marginLeft: filters.length > 0 ? "10px" : "0",
            }}
          >
            {t("Clear All Filters")}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ActiveFilters;
