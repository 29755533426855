import instance from "base_url";

// getFamily function
const getFamily = async (
  id,
  setFamily,
  setIsRedCrescent,
  setWaterBill,
  setGasBill,
  setElectricityBill,
  setInternetBill,
  setRentAmount,
  setemergencyButtonActive,
  setRent
) => {
  try {
    const { data } = await instance.get(`/families/${id}/`);
    setFamily(data);

    const redCrescent = data.incomes?.find(
      (item) => item.title?.item_name === "Red Crescent"
    );
    setIsRedCrescent(redCrescent && redCrescent.amount === 0 ? "No" : "Yes");

    const water = data.expenses?.find(
      (item) => item.title?.item_name === "Water Bill"
    );
    const gas = data.expenses?.find(
      (item) => item.title?.item_name === "Gas Bill"
    );
    const electricity = data.expenses?.find(
      (item) => item.title?.item_name === "Electricity Bill"
    );
    const internet = data.expenses?.find(
      (item) => item.title?.item_name === "Internet Bill"
    );
    const otherBill = data.expenses?.find(
      (item) => item.title?.item_name === "Other Bill"
    );
    const rentAmount = data.expenses?.find(
      (item) => item.title?.item_name === "Rent Amount"
    );

    setWaterBill(water);
    setGasBill(gas);
    setElectricityBill(electricity);
    setInternetBill(internet);
    setRent(rentAmount);

    setRentAmount(rentAmount?.amount || 0);
    setemergencyButtonActive(
      !(
        !data.is_draft ||
        !water ||
        !gas ||
        !electricity ||
        !internet ||
        !rentAmount
      )
    );
  } catch (e) {
    console.error(e);
  }
};


// age calculation
const calculateAgeCategories = (
  individuals,
  setUnderTwo,
  setOverTwo,
  setAdults,
  setSeniors
) => {
  const currentYear = new Date().getFullYear();
  let underTwoCount = 0;
  let overTwoCount = 0;
  let adultsCount = 0;
  let seniorsCount = 0;

  individuals.forEach((individual) => {
    const birthYear = new Date(individual.date_of_birth).getFullYear();
    const age = currentYear - birthYear;

    if (age < 2) {
      underTwoCount++;
    } else if (age >= 2 && age < 18) {
      overTwoCount++;
    } else if (age >= 18 && age < 55) {
      adultsCount++;
    } else if (age >= 55) {
      seniorsCount++;
    }
  });

   setUnderTwo(underTwoCount);
  setOverTwo(overTwoCount);
  setAdults(adultsCount);
  setSeniors(seniorsCount);
};

  // Function to calculate age based on date of birth

  const calculateAge = (dateOfBirth) => {
    const birthYear = new Date(dateOfBirth).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  }



  // Counters for condition and disability severities
  const calculateConditionSeverities = (individuals, setIllnessSeverity, setDisabilitySeverity, setIllnessDisabilitySeverity) => {
    const illnessCount = { 1: 0, 2: 0, 3: 0 };
    const disabilityCount = { 1: 0, 2: 0, 3: 0 };
    const illnessDisabilityCount = { 1: 0, 2: 0, 3: 0 };
  
    individuals.forEach((individual) => {
      if (individual.has_condition) {
        if (individual.condition_type === "illness") {
          illnessCount[individual.condition_severity]++;
        } else if (individual.condition_type === "disability") {
          disabilityCount[individual.condition_severity]++;
        }
        else if (individual.condition_type === "illness_Disability") {
          illnessDisabilityCount[individual.condition_severity]++;
        }
      }
    });
  
    setIllnessSeverity(illnessCount);
    setDisabilitySeverity(disabilityCount);
    setIllnessDisabilitySeverity(illnessDisabilityCount);

  };
  

// fetchFamilyIndividuals
const fetchFamilyIndividuals = async (
  familyId,
  setIndividuals,
  setFamily,
  setHeadOfFamily,
  setRedCrescent,
  setIsRedCrescent,
  setIncome,
  setTotalBills,
  setRentAmount,
  setLoading,
  setUnderTwo,
  setOverTwo,
  setAdults,
  setSeniors,
  setIllnessSeverity,
  setDisabilitySeverity,
  setIllnessDisabilitySeverity
) => {
  try {
    const { data } = await instance.get(`/families/${familyId}/`);

    setIndividuals(data.individuals || []);
    setFamily(data);

    const head = data.individuals.find((ind) => ind.is_head_of_family);
    setHeadOfFamily(head);

    const redCrescent = data.incomes?.find(
      (item) => item.title?.item_name === "Red Crescent"
    );
    setRedCrescent(redCrescent);
    if (redCrescent && redCrescent.amount == 0) {
      setIsRedCrescent("No");
    } else {
      setIsRedCrescent("Yes");
    }
    const income = data.incomes?.find(
      (item) => item.title?.item_name === "Salary"
    );

    
    setIncome(income?.amount || 0);

    const totalExpenses = [
      "Water Bill",
      "Gas Bill",
      "Electricity Bill",
      "Internet Bill",
      "Other",
    ].reduce(
      (sum, billType) =>
        sum +
        parseInt(
          data.expenses?.find((item) => item.title?.item_name === billType)
            ?.amount || 0
        ),
      0
    );
    setTotalBills(totalExpenses);

    const rent = data.expenses?.find(
      (item) => item.title?.item_name === "Rent Amount"
    );
    setRentAmount(rent?.amount || 0);

    calculateAgeCategories(
      data.individuals,
      setUnderTwo,
      setOverTwo,
      setAdults,
      setSeniors
    );

    calculateConditionSeverities(
      data.individuals,
      setIllnessSeverity,
      setDisabilitySeverity,
      setIllnessDisabilitySeverity
    );
  } catch (error) {
    console.error("Error fetching family data:", error);
  } finally {
    setLoading(false);
  }
};


 
 




export { getFamily, calculateAgeCategories , calculateAge , calculateConditionSeverities , fetchFamilyIndividuals  };
