import React, { useEffect, useState, useRef } from "react";
import instance from "base_url";
import Autocomplete from "components/Common/Autocomplete";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import MapAddress from "components/Common/MapAddress";
import { setErrorFn } from "../Utility/Functions";

import Breadcrumb from "components/Common/Breadcrumb";
import PersonalInformation from "components/Individual/PersonalInformation";
import FamilyInformation from "components/Individual/FamilyInformation";

// import jason data for banks
import turkeyBanks from "common/json/turkey_banks.json";

// import style.css from current directory
import "./style.css";
import ContactInformation from "components/Individual/ContactInformation";
import AdditionalInformation from "components/Individual/AdditionalInformation";

const defaultIndividual = {
  first_name: "",
  last_name: "",
  national_id: "",
  national_id_issue_place: "",
  date_of_birth: "",
  place_of_birth: "",
  job_title: "",
  salary: "",
  gender: "",
  partner_relationship: "",
  partner_relationship_status: "",
  mobile_number: "",
  email: "",
  address: "",
  location: "",
  family: null,
  mother: null,
  father: null,
  partner_id: null,
  status: "alive",
  is_head_of_family: false,
  stay_with_family: true,
  is_draft: true,
  notes: "",
  is_working: false,
};

const fields_tabs_mapping = {
  1: [
    "first_name",
    "first_name_tr",
    "last_name",
    "last_name_tr",
    "date_of_birth",
    "place_of_birth",
    "gender",
    "national_id",
    "national_id_issue_place",
    "status",
  ],
  2: ["father", "full_name_of_father", "mother", "partner_relationship"],
  3: ["job_title", "salary", "mobile_number", "email"],
  4: [],
};

function CreateOrEditIndividual({ t, i18n }) {
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    description: "",
    type: "",
  });
  const [activeTab, setActiveTab] = useState(1);
  const [individual, setIndividual] = useState(defaultIndividual);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const familyId = searchParams.get("family");
  const [newIndFamilyId, setNewIndFamilyId] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [error, setError] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [headOfFamily, setHeadOfFamily] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [relationship, setRelationship] = useState("");
  const [customRelationship, setCustomRelationship] = useState("");
  const [sehirData, setSehirData] = useState([]);
  const [ilceData, setIlceData] = useState([]);
  const [mahalleData, setMahalleData] = useState([]);
  const rtlDirection = i18n.language === "ar" ? "rtl" : "ltr";
  const [isOutsideCountrySelectedFather, setIsOutsideCountrySelectedFather] =
    useState(null);
  const [
    isOutsideCountrySelectedForMother,
    setIsOutsideCountrySelectedForMother,
  ] = useState(null);
  const [warningMessages, setWarningMessages] = useState({
    account_holder_name: "",
    last_name_tr: "",
    first_name_tr: "",
    place_of_birth_tr: "",
  });

  // Email
  const [emailPrefix, setEmailPrefix] = useState("");
  const [emailDomain, setEmailDomain] = useState("");

  const handleEmailPrefixChange = (e) => {
    setEmailPrefix(e.target.value);
    updateServerParams(`${e.target.value}${emailDomain}`, "email");
  };

  const handleEmailDomainChange = (e) => {
    setEmailDomain(e.target.value);
    updateServerParams(`${emailPrefix}${e.target.value}`, "email");
  };

  //ProfileImage
  const [profileImage, setProfileImage] = useState(null);
  const [initialProfileImage, setInitialProfileImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const webcamRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
        setIndividual((prevIndividual) => ({
          ...prevIndividual,
          profile_image: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const getSehirData = async () => {
    try {
      const { data } = await instance.get("/cities/");
      setSehirData(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  const getIlceData = async (city, fn) => {
    try {
      const { data } = await instance.get(`/districts/?search=${city}`);
      fn(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  const getMahalleData = async (district, fn) => {
    try {
      // const { data } = await instance.get(`/districts/${district.id}/neighborhoods/`);
      const { data } = await instance.get(`/neighborhoods/?search=${district}`);
      fn(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      // Convert base64 image to blob
      const base64Response = await fetch(imageSrc);
      const blob = await base64Response.blob();
      const file = new File([blob], "webcam_image.jpg", { type: "image/jpeg" });

      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
        setIndividual((prevIndividual) => ({
          ...prevIndividual,
          profile_image: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
      setShowWebcam(false);
    }
  };

  const toggleCamera = () => {
    setShowWebcam(!showWebcam);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // get visit id from query params (if the page is visited from a visits page)
  const visitId =
    new URLSearchParams(window.location.search).get("visit") ||
    individual?.last_updated_by_visit;

  // Get individual Details
  const getIndividualDetails = async () => {
    try {
      const { data } = await instance.get(`/individuals/${id}/`);
      data.mobile_number = data.mobile_number || data.family_mobile_number;
      setIndividual(data);
      setInitialProfileImage(data.profile_image);
      getFamilies(data.family);
      if (data.mother) {
        setIsOutsideCountrySelectedForMother(false);
      }
      if (data.mother_name_absent && data.mother_name_absent != "Unknown") {
        setIsOutsideCountrySelectedForMother(true);
      }

      if (data.father) {
        setIsOutsideCountrySelectedFather(false);
      }
      if (data.father_name_absent && data.father_name_absent != "Unknown") {
        setIsOutsideCountrySelectedFather(true);
      }

      if (data.profile_image) {
        setProfileImage(data.profile_image);
      }
    } catch (e) {
      console.error(e);
      setErrorOccurred(true);
      setError(e.message);
    }
  };

  // Get Families list
  const getFamilies = async (familyId) => {
    try {
      const { data } = await instance.get(`/families/${familyId}/`);
      // Set selected family if you are targeting details page
      setSelectedFamily(data);
      const headOfFamily = data.individuals.find(
        (individual) => individual.is_head_of_family
      );
      if (headOfFamily) {
        setHeadOfFamily(headOfFamily);
        individual.mobile_number = headOfFamily.mobile_number;
      }
    } catch (e) {
      console.error(e);
      // setErrorOccurred(true);
      // setError(e.message);
    }
  };

  useEffect(() => {
    // set default values from family head if exists else one of family
    if (id && id !== "new") {
      getIndividualDetails();
    }

    getFamilies(familyId);
    getSehirData(setSehirData);

    const headOfFamily = selectedFamily?.individuals?.find(
      (individual) => individual.is_head_of_family
    );
    if (headOfFamily) {
      setIndividual((prevIndividual) => ({
        ...prevIndividual,
        mobile_number: headOfFamily.mobile_number,
        email: headOfFamily.email,
        address: headOfFamily.address,
        location: headOfFamily.location,
      }));
    }
  }, [id]);

  const updateServerParams = (value, param) => {
    // check type of value if boolean or not
    if (typeof value === "boolean") {
      setIndividual((prevIndividual) => ({
        ...prevIndividual,
        [param]: value,
      }));
    } else {
      setIndividual((prevIndividual) => ({
        ...prevIndividual,
        [param]: value ? value : null,
      }));
    }
  };

  const saveIndividual = async () => {
    setIsFormSubmitted(true); // to show required fields
    setIsSaving(true);

    // const isInvalidForm = validateRequiredFields(individual.is_draft);
    const requiredFields = [
      "first_name",
      "first_name_tr",
      "last_name",
      "last_name_tr",
      "date_of_birth",
      "place_of_birth",
      "gender",
    ];

    var allRequiredFields = [
      ...requiredFields,
      "national_id",
      "national_id_issue_place",
      "mobile_number",
      "address",
      "partner_relationship",
    ];

    if (individual.is_working) {
      allRequiredFields.push("job_title", "salary");
    }

    if (["dead", "lost", "insyria"].includes(individual.status)) {
      allRequiredFields = allRequiredFields.filter((field) => {
        // field not in requiredFields
        return ![
          "mobile_number",
          "national_id",
          "national_id_issue_place",
          "address",
        ].includes(field);
      });
    }

    var errorFields = [];

    // get error fields
    if (individual.is_draft) {
      errorFields = requiredFields.filter(
        (field) => !individual[field] || individual[field] === ""
      );
      setErrorFields(errorFields);
    } else {
      errorFields = allRequiredFields.filter(
        (field) => !individual[field] || individual[field] === ""
      );
      setErrorFields(errorFields);
    }

    console.log("errorFields", errorFields);

    const isInvalidForm = errorFields.length > 0;
    if (isInvalidForm) {
      setAlert({
        show: true,
        message: "Error",
        description: "Some fields are missing!",
        type: "error",
      });
      setIsSaving(false);
      return;
    }

    const payload = {
      ...individual,
      family: familyId,
      last_updated_by_visit: visitId,
      profile_image: individual.profile_image || null,
    };

    if (payload.profile_image === initialProfileImage) {
      delete payload.profile_image;
    }

    try {
      if (id && id !== "new") {
        await instance.put(`/individuals/${id}/`, payload);
      } else {
        const newIndividualResponse = await instance.post(
          "/individuals/",
          payload
        );
        const familyId = newIndividualResponse.data.family;
        setNewIndFamilyId(familyId);
      }
      setShowModal(true);
    } catch (e) {
      setErrorFn(e, setErrorOccurred, setError);
    }
    setIsSaving(false);
  };

  const handleNavigateToFamilyDetails = () => {
    setShowModal(false);
    if (newIndFamilyId) {
      navigate(`/family/${newIndFamilyId}`);
    } else {
      navigate(-1);
    }
  };

  const handleAddNewIndividual = () => {
    setShowModal(false);
    navigate(
      `/individual/new?family=${newIndFamilyId}${
        visitId ? `&visit=${visitId}` : ""
      }`,
      { replace: true }
    );

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const handleStringInput = (e, param) => {
    const value = e.target.value;
    const regex = /^[^\d\u0660-\u0669\u06F0-\u06F9]*$/;

    if (regex.test(value)) {
      updateServerParams(value, param);
    }
  };

  const handleLocationChange = (latlng, address) => {
    updateServerParams(latlng, "location");
    updateServerParams(address, "address");
  };

  const getPartnerOptions = () => {
    var individualOptions = selectedFamily?.individuals?.filter(x => x.id !== individual.father && x.id !== individual.mother && x.id !== individual.id);
    if (individual && individual.gender == "male") {
      individualOptions = individualOptions?.filter((x) => x.gender == "female");
    } else if (individual && individual.gender == "female") {
      individualOptions = individualOptions?.filter((x) => x.gender == "male");
    } 
    return individualOptions;
  };

  // Handle input for TR
  const handleInputForTR = (e, param) => {
    const value = e.target.value;
    const regex = /^[a-zA-ZığüşöçİĞÜŞÖÇ ]*$/;
    if (regex.test(value)) {
      updateServerParams(value, param);
      setWarningMessages((prevMessages) => ({
        ...prevMessages,
        [param]: "",
      }));
    } else {
      setWarningMessages((prevMessages) => ({
        ...prevMessages,
        [param]: t("Only English or Turkish letters are allowed."),
      }));
    }
  };

  const handleKeyPressForTR = (e, param) => {
    const regex = /^[a-zA-ZığüşöçİĞÜŞÖÇ ]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      setWarningMessages((prevMessages) => ({
        ...prevMessages,
        [param]: t("Only English or Turkish letters are allowed."),
      }));
    }

    if (param === "iban") {
      const ibanKeyRegex = /^[A-Z0-9]*$/; // فقط أرقام وأحرف كبيرة
      if (!ibanKeyRegex.test(e.key)) {
        e.preventDefault();
        setWarningMessages((prevMessages) => ({
          ...prevMessages,
          [param]: t("Only uppercase letters and digits are allowed for IBAN."),
        }));
      }
    }
  };

  // Toggle manual address input and initialize dependent fields
  const [manualAddress, setManualAddress] = useState(false);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [selectedCityKey, setSelectedCityKey] = useState(null);
  const [selectedDistrictKey, setSelectedDistrictKey] = useState(null);
  const toggleManualAddress = () => {
    setManualAddress(!manualAddress);
    setIndividual((prevIndividual) => ({
      ...prevIndividual,
      address: manualAddress ? individual.address : "",
    }));
  };
  const handleCityChange = (value) => {
    setCity(value.name);
    setDistrict("");
    setNeighborhood("");
  };
  // Filtering districts based on selected city
  const filteredDistricts = ilceData.filter(
    (item) => item.ilce_sehirkey === selectedCityKey
  );

  // Filtering neighborhoods based on selected district
  const filteredNeighborhoods = mahalleData.filter(
    (item) => item.mahalle_ilcekey === selectedDistrictKey
  );

  // Banks
  const [banksData, setBanksData] = useState({ banks: [] });
  useEffect(() => {
    setBanksData(turkeyBanks);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content" dir={rtlDirection}>
        {errorOccurred && (
          <div
            className="alert alert-danger fade show"
            role="alert"
            style={{
              position: "fixed!important",
              bottom: "0",
              right: "0",
              top: "auto",
            }}
          >
            {error}
          </div>
        )}
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">
                  {id && id !== "new"
                    ? t("Edit Individual")
                    : t("Create Individual")}
                </h6>
              </Col>
            </Row>
            <div
              className="form-wizard-wrapper wizard clearfix"
              dir={rtlDirection}
            >
              <div className="steps clearfix">
                <ul>
                  {rtlDirection === "rtl" ? (
                    <>
                      <NavItem className={activeTab === 4 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(4)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            4.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Additional Details")}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 3 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(3)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            3.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Contact Information")}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 2 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(2)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            2.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Family Information ")}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 1 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(1)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            1.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Personal Information")}
                          </span>
                        </NavLink>
                      </NavItem>
                    </>
                  ) : (
                    <>
                      <NavItem className={activeTab === 1 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(1)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            1.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[1].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Personal Information")}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 2 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(2)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            2.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[2].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Family Information ")}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 3 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(3)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            3.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[3].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Contact Information")}
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={activeTab === 4 ? "current" : ""}>
                        <NavLink onClick={() => toggleTab(4)}>
                          <span
                            className="number"
                            style={{
                              border: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                              backgroundColor: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "red"
                                : "inherit",
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "white"
                                : "inherit",
                              borderRadius: "50%",
                              padding: "5px 10px",
                            }}
                          >
                            4.
                          </span>
                          <span
                            style={{
                              color: errorFields.some((x) =>
                                fields_tabs_mapping[4].includes(x)
                              )
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {t("Additional Details")}
                          </span>
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <PersonalInformation
                        individual={individual}
                        handleStringInput={handleStringInput}
                        handleInputForTR={handleInputForTR}
                        handleKeyPressForTR={handleKeyPressForTR}
                        updateServerParams={updateServerParams}
                        errorFields={errorFields}
                        isFormSubmitted={isFormSubmitted}
                        warningMessages={warningMessages}
                        sehirData={sehirData}
                        t={t}
                        />
                    </TabPane>
                    <TabPane tabId={2}>
                      <FamilyInformation
                        individual={individual}
                        selectedFamily={selectedFamily}
                        updateServerParams={updateServerParams}
                        errorFields={errorFields}
                        isFormSubmitted={isFormSubmitted}
                        t={t}
                        relationship={relationship}
                        setRelationship={setRelationship}
                        customRelationship={customRelationship}
                        setCustomRelationship={setCustomRelationship}
                        getPartnerOptions={getPartnerOptions}
                        />
                    </TabPane>
                    <TabPane tabId={3}>
                      <ContactInformation
                        individual={individual}
                        headOfFamily={headOfFamily}
                        updateServerParams={updateServerParams}
                        errorFields={errorFields}
                        emailPrefix={emailPrefix}
                        setEmailPrefix={setEmailPrefix}
                        emailDomain={emailDomain}
                        setEmailDomain={setEmailDomain}
                        manualAddress={manualAddress}
                        sehirData={sehirData}
                        setSehirData={setSehirData}
                        ilceData={ilceData}
                        setIlceData={setIlceData}
                        mahalleData={mahalleData}
                        getIlceData={getIlceData}
                        getMahalleData={getMahalleData}
                        setMahalleData={setMahalleData}
                        city={city}
                        setCity={setCity}
                        district={district}
                        setDistrict={setDistrict}
                        setNeighborhood={setNeighborhood}
                        street={street}
                        setStreet={setStreet}
                        building={building}
                        setBuilding={setBuilding}
                        floor={floor}
                        setFloor={setFloor}
                        setShowMap={setShowMap}
                        banksData={banksData}
                        handleInputForTR={handleInputForTR}
                        handleKeyPressForTR={handleKeyPressForTR}
                        warningMessages={warningMessages}
                        setWarningMessages={setWarningMessages}
                        selectedFamily={selectedFamily}
                        showMap={showMap}
                        handleLocationChange={handleLocationChange}
                        neighborhood={neighborhood}
                        t={t} 
                        />
                    </TabPane>
                    <TabPane tabId={4}>
                      <AdditionalInformation
                        profileImage={profileImage}
                        showWebcam={showWebcam}
                        webcamRef={webcamRef}
                        toggleCamera={toggleCamera}
                        captureImage={captureImage}
                        handleImageUpload={handleImageUpload}
                        individual={individual}
                        updateServerParams={updateServerParams}
                        handleStringInput={handleStringInput}
                        errorFields={errorFields}
                        isFormSubmitted={isFormSubmitted}
                        rtlDirection={rtlDirection}
                        t={t}
                        />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Actions */}
          <div className="actions clearfix">
            <div
              className="button-group"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div>
                <Button
                  className={activeTab === 1 ? "previous disabled" : "previous"}
                  onClick={() => toggleTab(activeTab - 1)}
                >
                  {t("Previous")}
                </Button>
              </div>
              <div>
                <Button
                  className={activeTab === 4 ? "next disabled" : "next"}
                  onClick={() => toggleTab(activeTab + 1)}
                >
                  {t("Next")}
                </Button>
              </div>
              {/* Save button */}
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={isSaving}
                  onClick={() => {
                    saveIndividual();
                  }}
                >
                  {t("Save")}
                </button>
              </div>
              {showModal && (
                <div
                  className="modal bs-example-modal"
                  tabIndex="-1"
                  onClick={(e) => {
                    if (e.target.className === "modal bs-example-modal") {
                      setShowModal(false);
                    }
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1050,
                  }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {t("Save Confirmation")}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowModal(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p style={{ textAlign: "center" }}>
                          <span>
                            {t("The individual has been saved successfully!")}
                          </span>
                          <span style={{ display: "block", marginTop: "10px" }}>
                            {t("Where would you like to go next?")}
                          </span>
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNavigateToFamilyDetails}
                        >
                          {t("Go to Family Details")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleAddNewIndividual}
                        >
                          {t("Add New Individual")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(CreateOrEditIndividual);
