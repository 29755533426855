import React from "react";
import Autocomplete from "components/Common/Autocomplete";
import moment from "moment";

//flatpickr
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import CalendarTranslations from "components/Common/CalendarTranslations";


export default function PersonalInformation({
    individual,
    handleStringInput,
    handleInputForTR,
    handleKeyPressForTR,
    updateServerParams,
    errorFields,
    isFormSubmitted,
    warningMessages,
    sehirData,
    t
}) {
    const calendarTranslations = CalendarTranslations();

    return (
        <>
        {/* First name */}
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                    <strong>{t("First name")}</strong>
                    <span style={{ color: "red" }}> * *</span>
                    </p>
                </div>
                <div className="col-4">
                    <input
                    className="form-control"
                    placeholder={t("Add First Name")}
                    value={individual.first_name}
                    onChange={(e) => handleStringInput(e, "first_name")}
                    style={{
                        border: errorFields.find(
                        (x) => x === "first_name"
                        )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    />
                </div>
                {/* "First name" in TR */}
                <div className="col-4">
                    <input
                    className="form-control"
                    placeholder={t("Add First Name in TR")}
                    value={individual.first_name_tr}
                    onChange={(e) =>
                        handleInputForTR(e, "first_name_tr")
                    }
                    onKeyPress={(e) =>
                        handleKeyPressForTR(e, "first_name_tr")
                    }
                    />
                    {warningMessages.first_name_tr && (
                    <div className="alert-warning">
                        {warningMessages.first_name_tr}
                    </div>
                    )}
                </div>
                </div>
                {/* Last name */}
                <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                    <strong>{t("Last name")}</strong>
                    <span style={{ color: "red" }}> * *</span>
                    </p>
                </div>
                <div className="col-4">
                    <input
                    className="form-control required"
                    placeholder={t("Add Last Name")}
                    value={individual.last_name}
                    onChange={(e) => handleStringInput(e, "last_name")}
                    style={{
                        border: errorFields.find(
                        (x) => x === "first_name"
                        )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    />
                </div>
                {/* "Last name" in TR */}
                <div className="col-4">
                    <input
                    className="form-control"
                    placeholder={t("Add Last Name in TR")}
                    value={individual.last_name_tr}
                    onChange={(e) =>
                        handleInputForTR(e, "last_name_tr")
                    }
                    onKeyPress={(e) =>
                        handleKeyPressForTR(e, "last_name_tr")
                    }
                    />
                    {warningMessages.last_name_tr && (
                    <div className="alert-warning">
                        {warningMessages.last_name_tr}
                    </div>
                    )}
                </div>
                </div>
                {/* Place of birth */}
                <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                    <strong>{t("Place of birth")}</strong>
                    <span style={{ color: "red" }}> * </span>
                    </p>
                </div>
                <div className="col-4">
                    <input
                    className="form-control"
                    placeholder={t("Add Place of birth")}
                    value={individual.place_of_birth}
                    onChange={(e) =>
                        handleStringInput(e, "place_of_birth")
                    }
                    style={{
                        border: errorFields.find(
                        (x) => x === "place_of_birth"
                        )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    />
                </div>

                {/* "Place of birth" in TR */}
                <div className="col-4">
                    <input
                    className="form-control"
                    placeholder={t("Add Place of birth in TR")}
                    value={individual.place_of_birth_tr}
                    onChange={(e) =>
                        handleInputForTR(e, "place_of_birth_tr")
                    }
                    onKeyPress={(e) =>
                        handleKeyPressForTR(e, "place_of_birth_tr")
                    }
                    />
                    {warningMessages.place_of_birth_tr && (
                    <div className="alert-warning">
                        {warningMessages.place_of_birth_tr}
                    </div>
                    )}
                </div>
                </div>

                {/* National ID Issue Place and National Id */}
                <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                    <strong>
                        {t("National ID Issue Place / National Id")}
                    </strong>
                    </p>
                </div>
                <div className="col-4">
                    <Autocomplete
                    name={t("IDIssuePlace")}
                    searchParam="name"
                    placeholder={
                        individual.national_id_issue_place ||
                        t("Select National ID Issue Place")
                    }
                    searchApi={false}
                    basePlaceholder={t(
                        "Select National ID Issue Place"
                    )}
                    list={sehirData}
                    selectedObject={(value) => {
                        updateServerParams(
                        value?.name,
                        "national_id_issue_place"
                        );
                    }}
                    isError={
                        isFormSubmitted &&
                        errorFields.find(
                        (x) => x === "national_id_issue_place"
                        )
                    }
                    hideOverlay={true}
                    />
                </div>
                <div className="col-4">
                    <input
                    className="form-control draftNotRequired"
                    placeholder={t("Add National Id")}
                    type="number"
                    inputMode="numeric"
                    pattern="\d*"
                    min="0"
                    value={individual.national_id}
                    onChange={(e) => {
                        let nationalIdValue = e.target.value;
                        if (nationalIdValue.length > 11) {
                        nationalIdValue = nationalIdValue.slice(0, 11);
                        }

                        updateServerParams(
                        nationalIdValue,
                        "national_id"
                        );
                    }}
                    style={{
                        border: errorFields.find(
                        (x) => x === "national_id"
                        )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    />
                </div>
                </div>
                {/* Date of birth */}
                <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p>
                    {t("Date of birth")}{" "}
                    <span style={{ color: "red" }}>*</span> /{" "}
                    {t("Gender")}{" "}
                    <span style={{ color: "red" }}>*</span>
                    </p>
                </div>
                <div className="col-4">
                    <Flatpickr
                    className="form-control d-block"
                    placeholder={individual.date_of_birth ? moment(individual.date_of_birth).format("YYYY-MM-DD") : t("Add Date of birth")}
                    options={{
                        mode: "single",
                        dateFormat: "Y-m-d",
                        locale: {
                        weekdays: {
                            shorthand: calendarTranslations.shortDays,
                            longhand: calendarTranslations.longhand,
                        },
                        months: {
                            shorthand: calendarTranslations.months,
                            longhand: calendarTranslations.months,
                        },
                        },
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                        updateServerParams(dateStr, "date_of_birth");
                    }}
                    style={{
                        border: errorFields.find(
                        (x) => x === "date_of_birth"
                        )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    />
                </div>
                {/*  Gender */}
                <div className="col-4">
                    <select
                    className={`form-control form-select ${
                        isFormSubmitted && !individual.gender
                        ? "is-invalid"
                        : ""
                    }`}
                    value={individual.gender}
                    onChange={(e) => {
                        updateServerParams(e.target.value, "gender");
                    }}
                    style={{
                        border: errorFields.find((x) => x === "gender")
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    >
                    <option>{t("Select a gender")}</option>
                    <option value="male">{t("Male")}</option>
                    <option value="female">{t("Female")}</option>
                    <option value="other">{t("Other")}</option>
                    </select>
                </div>
                </div>
                {/* Status */}
                <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                    <strong>{t("Status")}</strong>
                    </p>
                </div>
                <div className="col-4">
                    <select
                    className={`form-control form-select ${
                        isFormSubmitted && !individual.gender
                        ? "is-invalid"
                        : ""
                    }`}
                    value={individual.status}
                    onChange={(e) => {
                        if (["dead", "lost", "insyria"].includes(e.target.value)) {
                        updateServerParams(false, "is_head_of_family");
                        }
                        updateServerParams(e.target.value, "status");
                    }}
                    style={{
                        border: errorFields.find((x) => x === "status")
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    >
                    <option value="alive">{t("Alive")}</option>
                    <option value="dead">{t("Dead")}</option>
                    <option value="lost">{t("Lost")}</option>
                    <option value="insyria">{t("In Syria")}</option>
                    </select>
                </div>
                </div>
                
                {/* Is Head Of Family */}
                <div className="row mb-4">
                    <div className="col-2 align-content-center">
                        <p className="m-0">
                        <strong>{t("Is head of family")}</strong>
                        </p>
                    </div>
                    <div className="col-4">
                        <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            checked={individual.is_head_of_family}
                            type="checkbox"
                            role="switch"
                            onChange={(e) => {
                            updateServerParams(
                                e.target.checked,
                                "is_head_of_family"
                            );
                            }}
                            disabled={individual.status === "dead" || individual.status === "lost" || individual.status === "insyria"}
                        />
                        </div>
                    </div>
                    {/*  Stay with Family */}
                    <div className="col-2 align-content-center">
                        <p className="m-0">
                        <strong>{t("Stay with family?")}</strong>
                        </p>
                    </div>
                    <div className="col-4">
                        <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            checked={individual.stay_with_family}
                            type="checkbox"
                            role="switch"
                            onChange={(e) => {
                            updateServerParams(
                                e.target.checked,
                                "stay_with_family"
                            );
                            }}
                        />
                        </div>
                    </div>
                </div>
        </>
    )
}