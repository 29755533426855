import React, { useEffect, useState } from "react";
import instance from "base_url";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MEDIA_URL } from "../../configs";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import logoSm from "../../assets/images/logo-sm.png";
import jsPDF from "jspdf";
import FamilyReportTables from "components/family/FamilyCommon/FamilyReportTables";
import {
  calculateAgeCategories,
  calculateConditionSeverities,
  fetchFamilyIndividuals,
  calculateAge,
} from "../Utility/Functions/familyTables";

const FamilyReport = () => {
  const { id, familyId } = useParams();
  const { t, i18n } = useTranslation();
  const [individuals, setIndividuals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [family, setFamily] = useState({});
  const [headOfFamily, setHeadOfFamily] = useState(null);
  const [rentAmount, setRentAmount] = useState(0);
  const [isRedCrescent, setIsRedCrescent] = useState(null);
  const [redCrescent, setRedCrescent] = useState(null);
  const [totalBills, setTotalBills] = useState(0);
  const [income, setIncome] = useState(0);
  const [volunteerName, setVolunteerName] = useState("0");
  const [requestDate] = useState(new Date().toLocaleDateString());
  
  // Counters for age categories
  const [underTwo, setUnderTwo] = useState(0);
  const [overTwo, setOverTwo] = useState(0);
  const [adults, setAdults] = useState(0);
  const [seniors, setSeniors] = useState(0);
  useEffect(() => {
    if (individuals.length > 0) {
      calculateAgeCategories(
        individuals,
        setUnderTwo,
        setOverTwo,
        setAdults,
        setSeniors
      );
    }
  }, [individuals]);

  useEffect(() => {
    if (individuals.length > 0) {
      calculateConditionSeverities(
        individuals,
        setIllnessSeverity,
        setDisabilitySeverity,
        setIllnessDisabilitySeverity
      );
    }
  }, [individuals]);

  // Fetch family and individual data
  useEffect(() => {
    fetchFamilyIndividuals(
      familyId,
      setIndividuals,
      setFamily,
      setHeadOfFamily,
      setRedCrescent,
      setIsRedCrescent,
      setIncome,
      setTotalBills,
      setRentAmount,
      setUnderTwo,
      setOverTwo,
      setAdults,
      setSeniors,
      setIllnessSeverity,
      setDisabilitySeverity,
      setIllnessDisabilitySeverity,
      setLoading
    );
  }, [familyId]);

  // Calculate illness and disability severities
  const [illnessDisabilitySeverity, setIllnessDisabilitySeverity] = useState(0);
  const [illnessSeverity, setIllnessSeverity] = useState(0);
  const [disabilitySeverity, setDisabilitySeverity] = useState(0);
  


  // Function to download PDF with optimizations
  const downloadPDF = async () => {
    const input = document.getElementById("pdf-content");
    const buttons = document.querySelectorAll(".d-print-none");

    // Hide buttons temporarily
    buttons.forEach((button) => (button.style.display = "none"));

    // Ensure all images are fully loaded
    const images = input.querySelectorAll("img");
    await Promise.all(
      Array.from(images).map((img) =>
        img.complete
          ? Promise.resolve()
          : new Promise((resolve) => {
              img.onload = resolve;
              img.onerror = resolve;
            })
      )
    );

    // Capture the content as a lower-resolution canvas
    const canvas = await html2canvas(input, {
      scale: 2.5, // Reduced scale factor for smaller file size
      useCORS: true,
    });
    const imgData = canvas.toDataURL("image/jpeg", 0.8); // Use JPEG format with quality 0.8

    // Create a new jsPDF instance
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    // Draw the canvas image onto the PDF
    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    // Set file name dynamically based on family details
    const headOfFamilyName = headOfFamily
      ? `${headOfFamily.first_name}_${headOfFamily.last_name}`
      : "Unknown";
    const fileName = `${t("Family Report")}_${headOfFamilyName}.pdf`;

    // Save the PDF
    pdf.save(fileName);

    // Show buttons again
    buttons.forEach((button) => (button.style.display = "block"));
  };

  const isRTL = i18n.language === "ar";

  return (
    <React.Fragment>
      <div className="page-content" dir={isRTL ? "rtl" : "ltr"}>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={5}>
                <h6 className="page-title">{t("Family Report")}</h6>
              </Col>
            </Row>
          </div>
          <Row>
            <div className="col-15">
              <Card>
                <CardBody id="pdf-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "2px",
                    }}
                  >
                    <div style={{ fontSize: "14px", color: "#333" }}>
                      <h4
                        style={{
                          margin: 0,
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Family Id")}: {familyId}
                      </h4>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          color: "#333",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Request Date")}: {new Date().toLocaleDateString()}
                      </p>
                    </div>

                    <h3 style={{ marginTop: 0, fontSize: "18px" }}>
                      <img src={logoSm} alt="logo" height="30" />
                    </h3>
                  </div>
                  <hr style={{ marginTop: "1px", borderColor: "#343a40" }} />
                  {/* Individual information table */}
                  <Row>
                    <FamilyReportTables
                      individuals={individuals}
                      headOfFamily={headOfFamily}
                      calculateAge = {calculateAge}
                      visibleTables={["individuals"]}
                    />
                  </Row>
                  <Row>
                    {/* Family Tree Section */}
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <div
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: "2px",
                                color: "#333",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("Family Tree")}
                            </div>
                            <div
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                backgroundColor: "#f9f9f9",
                                marginBottom: "2px",
                              }}
                            >
                              <img
                                src={`${MEDIA_URL}genogram/${family.id}.png`}
                                alt="Family Tree"
                                style={{ width: "80%", height: "auto" }}
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {/* Request Information Section */}
                    <Col md={6} style={{ marginBottom: "2px" }}>
                      <Card>
                        <CardBody>
                          <FamilyReportTables
                            individuals={individuals}
                            headOfFamily={headOfFamily}
                            rentAmount={rentAmount}
                            isRedCrescent={isRedCrescent}
                            redCrescent={redCrescent}
                            income={income}
                            totalBills={totalBills}
                            visibleTables={["request"]}
                          />
                          {/* Medical Condition / Disability  */}
                          <FamilyReportTables
                            individuals={individuals}
                            illnessSeverity={illnessSeverity}
                            disabilitySeverity={disabilitySeverity}
                            illnessDisabilitySeverity={illnessDisabilitySeverity}
                            visibleTables={["medical"]}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    {/* Request Details Section */}
                    <Col md={6}>
                      <Card style={{ marginBottom: "2px" }}>
                        <CardBody>
                          <FamilyReportTables
                            individuals={individuals}
                            headOfFamily={headOfFamily}
                            visibleTables={["requestDetails"]}
                          />
                          {/* Age Categories */}
                          <FamilyReportTables
                            individuals={individuals}
                            underTwo={underTwo}
                            overTwo={overTwo}
                            adults={adults}
                            seniors={seniors}
                            visibleTables={["ageCategories"]}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {/* Signature Section */}
                  <div
                    style={{
                      borderTop: "1px solid #343a40",
                      marginTop: "20px",
                    }}
                  ></div>
                  <Row
                    className="justify-content-between"
                    style={{ marginTop: "20px", marginBottom: "70px" }}
                  >
                    <Col md={5} className="text-center">
                      <p
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "#333",
                        }}
                      >
                        {t("Volunteer Name")}
                      </p>
                      <div
                        style={{
                          borderTop: "2px solid #343a40",
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col md={5} className="text-center">
                      <p
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "#333",
                        }}
                      >
                        {t("Applicant and Signature")}
                      </p>
                      <div
                        style={{
                          borderTop: "2px solid #343a40",
                          width: "100%",
                        }}
                      />
                    </Col>
                  </Row>
                  {/* Uncomment this part if you want to enable the Emergency modal */}
                  {/* <Emergency
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(false)}
                    setDescription={(desc) => {
                      setDescription(desc);
                    }}
                  /> */}
                </CardBody>
              </Card>
              <div className="d-print-none">
                <div className="d-flex justify-content-center">
                  <Button color="success" onClick={() => downloadPDF()}>
                    <i className="fa fa-file-pdf"></i> {t("Download PDF")}
                  </Button>{" "}
                  {/* <Button color="primary" onClick={() => setIsModalOpen(true)}>
                      {t("Send")} </Button> */}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FamilyReport;
