import React, { useEffect, useState } from "react";
import instance from "base_url";
import { MDBDataTable } from "mdbreact";
import { Link, useParams } from "react-router-dom";
import { Table } from "reactstrap";

const FamilyTables = ({ tableType, t, id, legend }) => {
  const visitId = new URLSearchParams(window.location.search).get("visit");
  const [rentAmount, setRentAmount] = useState(0);
  const [isRedCrescent, setIsRedCrescent] = useState(t("No"));
  const [redCrescent, setRedCrescent] = useState({});
  const [familyInfo, setFamilyInfo] = useState([]);
  const [emergencies, setEmergencies] = useState([]);
  const [family, setFamily] = useState({});
  const headOfFamily = family.individuals?.find(
    (item) => item.is_head_of_family
  );

  const getFamily = async () => {
    if (!id) return;
    try {
      const { data } = await instance.get(`/families/${id}/`);
      setFamily(data);

      const redCrescent = data.incomes?.find(
        (item) => item.title?.item_name === "Red Crescent"
      );
      setRedCrescent(redCrescent);
      if (redCrescent && redCrescent.amount == 0) {
        setIsRedCrescent(t("No"));
      } else if (redCrescent) {
        setIsRedCrescent(t("Yes"));
      }

      const rentAmount = data.expenses?.find(
        (item) => item.title?.item_name === "Rent Amount"
      );

      setRentAmount(rentAmount?.amount || 0);
    } catch (e) {
      console.error(e);
    }
  };

  const getFamilyEmergencySituations = async () => {
    if (!id) return;
    try {
      const { data } = await instance.get(
        `/families/${id}/emergency_situations/`
      );
      setEmergencies(data);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getFamily();
    getFamilyEmergencySituations();
    setFamilyInfo([
      {
        bills: t("Paid"),
        red_crescent: "Yes",
      },
    ]);
  }, [id]);

  console.log(family);

  // Function to return status icon based on the status value
  const renderStatusIcon = (status) => {
    switch (status) {
      case "pending":
        return <i className="fas fa-clock text-warning"></i>;
      case "processing":
        return <i className="fas fa-spinner text-info"></i>;
      case "approved":
        return <i className="fas fa-check-circle text-success"></i>;
      case "rejection":
        return <i className="fas fa-times-circle text-danger"></i>;
      default:
        return <i className="fas fa-times-circle text-danger"></i>;
    }
  };

  // formatDescription
  const formatDescription = (description) => {
    return description && description.includes("Details:")
      ? description.split("Details:")[1].trim()
      : description || t("No details available.");
  };

  //truncateText
  const truncateText = (text, maxLength) => {
    if (!text) return t("No details available.");
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const getData = (type) => {
    switch (type) {
      // familyMembers  table
      case "familyMembers":
        return {
          columns: [
            {
              label: <span>{t("ID")}</span>,
              field: "id",
              sort: "asc",
              width: 100,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Name")}
                </span>
              ),
              field: "name",
              sort: "asc",
              width: 200,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Phone No.")}
                </span>
              ),
              field: "phone_no",
              sort: "asc",
              width: 200,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Gender")}
                </span>
              ),
              field: "gender",
              sort: "asc",
              width: 100,
            },
            {
              label: <span>{t("Address")}</span>,
              field: "address",
              sort: "disabled",
              width: 200,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Status")}
                </span>
              ),
              field: "status",
              sort: "asc",
              width: 100,
            },
            {
              label: <span>{t("Action")}</span>,
              field: "action",
              sort: "disabled",
              width: 150,
            },
          ],

          rows:
            family?.individuals
              ?.sort((a, b) => {
                if (a.is_head_of_family) return -1;
                if (b.is_head_of_family) return 1;
                if (a.id === headOfFamily?.partner_id) return -1;
                if (b.id === headOfFamily?.partner_id) return 1;
                return 0;
              })
              .map((item) => ({
                id: item.id,
                name: (
                  <>
                    {item.is_head_of_family && (
                      <i
                        className="fas fa-crown"
                        style={{
                          color: "#FFD700",
                          marginRight: "10px",
                          fontSize: "15px",
                          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
                        }}
                        title={t("Head of Family")}
                      ></i>
                    )}
                    {item.id === headOfFamily?.partner_id && (
                      <i
                        className="fas fas fa-gem"
                        style={{
                          color: "pink",
                          marginRight: "10px",
                          fontSize: "15px",
                          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
                        }}
                        title={t("Wife's")}
                      ></i>
                    )}
                    {item.name}
                  </>
                ),
                phone_no: (
                  <>
                    {item.mobile_number} -{" "}
                    <a href={`tel:${item.mobile_number}`}>{t("Call")}</a>
                  </>
                ),
                gender: t(item.gender),
                address: (
                  <>
                    {t("Address")}{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.google.com/maps?q=${item.location?.split(",")[0]
                        },${item.location?.split(",")[1]}`}
                    >
                      {t("see on map")}
                    </a>
                  </>
                ),
                status: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      style={{
                        color:
                          item.status === "alive"
                            ? "green"
                            : item.status === "dead"
                              ? "red"
                              : "black",
                      }}
                    >
                      {t(item.status)}
                    </span>
                    {item.has_condition && item.condition_severity > 0 && (
                      <>
                        {item.condition_type === "illness" && (
                          <i
                            className="fas fa-notes-medical"
                            style={{
                              color: "#ff9800",
                              marginLeft: "10px",
                              fontSize: "16px",
                            }}
                            title={t("Has Medical Condition")}
                          ></i>
                        )}
                        {item.condition_type === "disability" && (
                          <i
                            className="fas fa-wheelchair"
                            style={{
                              color: "#4caf50",
                              marginLeft: "10px",
                              fontSize: "16px",
                            }}
                            title={t("Has Disability")}
                          ></i>
                        )}
                        {item.condition_type === "illness_Disability" && (
                          <i
                            className="fas fa-clinic-medical"
                            style={{
                              color: "#9c27b0",
                              marginLeft: "10px",
                              fontSize: "16px",
                            }}
                            title={t(
                              "Has Both Medical and Disability Conditions"
                            )}
                          ></i>
                        )}
                      </>
                    )}
                  </div>
                ),
                action: (
                  <Link
                    to={`/individual/${item.id}?family=${family.id}${visitId ? `&visit=${visitId}` : ""
                      }`}
                    className={`btn btn-sm btn-${item.is_draft ? "warning" : "primary"
                      }`}
                  >
                    {t("Details")} {item.is_draft ? `(${t("Draft")})` : ""}
                  </Link>
                ),
              })) || [],
          legend: (
            <div style={{}}>
              <h6 style={{ color: "#555" }}> {t("Meanings of signs")}:</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {[
                  {
                    icon: "fa-crown",
                    color: "#FFD700",
                    text: t("Head of Family"),
                  },
                  {
                    icon: "fas fa-gem",
                    color: "pink",
                    text: t("Wife's"),
                  },
                  {
                    icon: "fa-notes-medical",
                    color: "#ff9800",
                    text: t("Medical"),
                  },
                  {
                    icon: "fa-wheelchair",
                    color: "#4caf50",
                    text: t("Disability"),
                  },
                  {
                    icon: "fa-clinic-medical",
                    color: "#9c27b0",
                    text: t("Both Medical and Disability"),
                  },
                ].map((legend, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      color: "#555",
                    }}
                  >
                    <i
                      className={`fas ${legend.icon}`}
                      style={{
                        color: legend.color,
                        marginInlineEnd: "8px",
                        fontSize: "16px",
                      }}
                    ></i>
                    <span>{legend.text}</span>
                  </div>
                ))}
              </div>
            </div>
          ),
        };

      // family information table
      case "familyInfo":
        return {
          columns: [
            {
              label: t("Family Total"),
              field: "familyTotal",
              sort: "asc",
              width: 100,
            },
            {
              label: t("Husband's Salary"),
              field: "headOfFamilySalary",
              sort: "asc",
              width: 100,
            },
            {
              label: t("Wife's Salary"),
              field: "wifeSalary",
              sort: "asc",
              width: 100,
            },
            {
              label: t("Rent Amount"),
              field: "rentAmount",
              sort: "asc",
              width: 100,
            },
            {
              label: t("Red Crescent"),
              field: "redCrescent",
              sort: "asc",
              width: 100,
            },
            {
              label: t("Total of Bills"),
              field: "totalBills",
              sort: "asc",
              width: 100,
            },
            {
              label: t("Action"),
              field: "action",
              sort: "disabled",
              width: 150,
            },
          ],
          rows: familyInfo.map((info) => ({
            familyTotal: family.expenses_summary?.remaining || 0,
            headOfFamilySalary: headOfFamily
              ? Math.floor(headOfFamily.salary)
              : 0,
            wifeSalary: Math.floor(
              family.individuals?.find(
                (individual) => individual.id === headOfFamily?.partner_id
              )?.salary || 0
            ),
            rentAmount: rentAmount ? Math.floor(rentAmount) : 0,
            redCrescent: isRedCrescent,
            totalBills: family.expenses_summary?.total_bills || 0,
            action: (
              <Link to={`/familyinfo/${id}`} className="btn btn-sm btn-primary">
                {t("Details")}
              </Link>
            ),
          })),
        };

      // emergencies table
      case "emergencies":
        return {
          columns: [
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("ID")}
                </span>
              ),
              field: "id",
              sort: "asc",
              width: 100,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Applicant")}
                </span>
              ),
              field: "applicant",
              sort: "asc",
              width: 150,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Recipient")}
                </span>
              ),
              field: "name",
              sort: "asc",
              width: 200,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Support Type")}
                </span>
              ),
              field: "support_category",
              sort: "asc",
              width: 200,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Description")}
                </span>
              ),
              field: "description",
              sort: "asc",
              width: 150,
            },
            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("status")}
                </span>
              ),
              field: "status",
              sort: "asc",
              width: 150,
            },

            {
              label: (
                <span>
                  <i className="fas fa-sort"></i> {t("Action")}
                </span>
              ),
              field: "action",
              sort: "disabled",
              width: 150,
            },
          ],

          rows:
            emergencies?.map((item) => ({
              id: item.id,
              name:
                item.individual?.name ||
                item.family?.title ||
                t("No individual or family"),
              support_category: t(item.support_category),
              description: truncateText(
                formatDescription(item.description),
                25
              ),
              applicant: item.applicant?.name || t("Unknown"),
              status: (
                <span>
                  {renderStatusIcon(item.status)} {t(item.status)}
                </span>
              ),
              action: (
                <Link
                  to={`/families/${family.id}/emergency/${item.id}`}
                  className={`btn btn-sm btn-${item.is_draft ? "warning" : "primary"
                    }`}
                >
                  {t("Details")}
                </Link>
              ),
            })) || [],
        };

      default:
        return { columns: [], rows: [] };
    }
  };

  const tableData = getData(tableType);

  return (
    <>
      <MDBDataTable
        searchLabel={t("Search")}
        info={false}
        noRecordsFoundLabel={t("No records found")}
        paging={false}
        noBottomColumns={true}
        responsive
        striped
        bordered
        data={tableData}
        displayEntries={false}
        pagination
      />
      {legend && tableData.legend && <div>{tableData.legend}</div>}
    </>
  );
};

export default FamilyTables;
