import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Treearchive from "components/family/treearchive";
import { Accordion } from "react-bootstrap";
import "./visitStyle.css";
import { Link } from "react-router-dom";

// Family Tables
import FamilyTables from "components/family/FamilyCommon/FamilyTables";
import FamilyReportTables from "components/family/FamilyCommon/FamilyReportTables";
import { getFamily } from "pages/Utility/Functions/familyTables";
import {
  calculateAgeCategories,
  calculateConditionSeverities,
  fetchFamilyIndividuals,
  calculateAge,
} from "../../Utility/Functions/familyTables";

const IndividualsInformation = ({
  family,
  setFamily,
  id,
  sendTreeReport,
  sending,
  isTreearchiveOpen,
  toggleTreearchiveModal,
  rtlDirection,
  t,
  visit,
  headOfFamily,
  redCrescent,
  income,
  totalBills,
  MEDIA_URL,
  illnessSeverity,
  disabilitySeverity,
  illnessDisabilitySeverity,
  underTwo,
  overTwo,
  adults,
  seniors,
  setUnderTwo,
  setOverTwo,
  setAdults,
  setSeniors,
  setIllnessSeverity,
  setDisabilitySeverity,
  visitId,
  legend,
  individuals,
}) => {
  const [familyId, setFamilyId] = useState(null);
  const [isRedCrescent, setIsRedCrescent] = React.useState("");
  const [waterBill, setWaterBill] = React.useState(null);
  const [gasBill, setGasBill] = React.useState(null);
  const [electricityBill, setElectricityBill] = React.useState(null);
  const [internetBill, setInternetBill] = React.useState(null);
  const [rent, setRent] = React.useState(null);
  const [rentAmount, setRentAmount] = React.useState(0);
  const [emergencyButtonActive, setemergencyButtonActive] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (familyId) {
      getFamily(
        familyId,
        setFamily,
        setIsRedCrescent,
        setWaterBill,
        setGasBill,
        setElectricityBill,
        setInternetBill,
        setRentAmount,
        setemergencyButtonActive,
        setRent
      );
    }
  }, [familyId]);

  // Fetch family and individual data
  // useEffect(() => {
  //   fetchFamilyIndividuals(
  //     setIndividuals,
  //     setIsRedCrescent,
  //     setRentAmount,
  //     setUnderTwo,
  //     setOverTwo,
  //     setAdults,
  //     setSeniors,
  //     setIllnessSeverity,
  //     setDisabilitySeverity,
  //     setLoading
  //   );
  // }, [familyId]);

  useEffect(() => {
    if (individuals.length > 0) {
      calculateAgeCategories(
        individuals,
        setUnderTwo,
        setOverTwo,
        setAdults,
        setSeniors
      );
    }
  }, [individuals]);

  // add new individual
  const handleAddNewIndividual = () => {
    setShowModal(false);
    navigate(
      `/individual/new?family=${newIndFamilyId}${
        visitId ? `&visit=${visitId}` : ""
      }`,
      { replace: true }
    );

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [newIndFamilyId, setNewIndFamilyId] = useState(null);

  


  return (
    <div className="mb-3">
      {/*  family tree btn */}
      <Accordion defaultActiveKey={null} className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("Family Tree")}</Accordion.Header>
          <Accordion.Body>
            <button
              className="btn btn-danger mb-4"
              onClick={sendTreeReport}
              style={{ marginRight: "10px" }}
            >
              {sending ? "sending ..." : t("Send Report to Email")}
            </button>
            <button
              className="btn btn-primary mb-4"
              onClick={toggleTreearchiveModal}
            >
              {t("Tree archive")}
            </button>

            <Treearchive
              isOpen={isTreearchiveOpen}
              toggle={toggleTreearchiveModal}
              familyId={id}
            />
            <br />
            <img
              src={`${MEDIA_URL}genogram/${family?.id}.png`}
              alt="Family Tree"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div>
        {/* Family Members Section */}
        <Accordion defaultActiveKey={null} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("Family Members")}</Accordion.Header>
            <Accordion.Body>
              <Link
                to={`/individual/new?family=${family?.id}${
                  visitId ? `&visit=${visitId}` : ""
                }`}
                className="btn btn-secondary text-decoration-none"
              >
                <i
                  className={`fas fa-user-plus ${
                    rtlDirection === "rtl" ? "ms-2" : "me-2"
                  }`}
                ></i>
                {t("Add New Individual")}
              </Link>

              <FamilyTables tableType="familyMembers" id={family?.id} t={t} legend={true} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* Family Report Section */}
      <Accordion defaultActiveKey={null} className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("Family Report")}</Accordion.Header>
          <Accordion.Body>
            <div className="family-report-container">
              {/* Age Categories */}
              <div className="family-report-table">
                <FamilyReportTables
                  individuals={individuals}
                  underTwo={underTwo}
                  overTwo={overTwo}
                  adults={adults}
                  seniors={seniors}
                  visibleTables={["ageCategories"]}
                />
              </div>
              {/* Medical Condition / Disability */}
              <div className="family-report-table">
                <FamilyReportTables
                  individuals={individuals}
                  illnessSeverity={illnessSeverity}
                  disabilitySeverity={disabilitySeverity}
                  illnessDisabilitySeverity={illnessDisabilitySeverity}
                  visibleTables={["medical"]}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default IndividualsInformation;
