import React from "react";
import Webcam from "react-webcam";
import { Button } from "reactstrap";

export default function AdditionalInformation({
  profileImage,
  showWebcam,
  webcamRef,
  toggleCamera,
  captureImage,
  handleImageUpload,
  individual,
  updateServerParams,
  handleStringInput,
  errorFields,
  isFormSubmitted,
  rtlDirection,
  t,
}) {
  return (
    <>
      {/* Profile Image */}
      <div className="d-flex justify-content-center align-items-center flex-column mb-4">
        <div className="image-upload">
          {showWebcam ? (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="image-upload video"
            />
          ) : profileImage ? (
            <img
              src={profileImage}
              alt="Profile"
              className="image-upload img"
            />
          ) : (
            <p>{t("Click to upload or take a photo")}</p>
          )}
        </div>
        <div className="mt-2">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="fileInput"
          />
          <div
            style={{
              display: "flex",
              flexDirection: rtlDirection === "rtl" ? "row-reverse" : "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              color="primary"
              onClick={() =>
                showWebcam
                  ? toggleCamera()
                  : document.getElementById("fileInput").click()
              }
            >
              {showWebcam ? t("Close Camera") : t("Upload Photo")}
            </Button>

            <Button
              color="secondary"
              onClick={showWebcam ? captureImage : toggleCamera}
            >
              {showWebcam ? t("Capture Photo") : t("Open Camera")}
            </Button>
          </div>
        </div>
      </div>

      {/* Section for Medical Condition/Disability */}
      <div className="row mb-4">
        <div className="col-2 align-content-center">
          <p className="m-0">
            <strong>{t("Has Medical Condition/Disability")}</strong>
          </p>
        </div>
        <div className="col-4">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              checked={individual.has_condition}
              type="checkbox"
              role="switch"
              onChange={(e) => {
                updateServerParams(e.target.checked, "has_condition");
              }}
              style={{
                border: errorFields.find((x) => x === "has_condition")
                  ? "1px solid red"
                  : "1px solid #ced4da",
              }}
            />
          </div>
        </div>
      </div>
      {individual.has_condition ? (
        <>
          {/* Options for Disability or Illness */}
          <div className="row mb-4">
            <div className="col-2 align-content-center">
              <p className="m-0">
                <strong>{t("Condition Type")}</strong>
                <span style={{ color: "red" }}> * </span>
              </p>
            </div>
            <div className="col-4">
              <select
                className={`form-control form-select ${
                  isFormSubmitted && !individual.condition_type
                    ? "is-invalid"
                    : ""
                }`}
                value={individual.condition_type || ""}
                onChange={(e) =>
                  updateServerParams(e.target.value, "condition_type")
                }
                style={{
                  border: errorFields.find((x) => x === "condition_type")
                    ? "1px solid red"
                    : "1px solid #ced4da",
                }}
              >
                <option value="">{t("Select condition type")}</option>
                <option value="disability">{t("Disability")}</option>
                <option value="illness">{t("Illness")}</option>
                <option value="illness_Disability">
                  {t("Illness and Disability")}
                </option>
              </select>
            </div>
          </div>

          {/* Condition Severity Section */}
          <div className="row mb-4">
            <div className="col-2 align-content-center">
              <p className="m-0">
                <strong>{t("Condition Severity")}</strong>
                <span style={{ color: "red" }}> * </span>
              </p>
            </div>
            <div className="col-4">
              <select
                className="form-control form-select"
                value={individual.condition_severity || ""}
                onChange={(e) =>
                  updateServerParams(e.target.value, "condition_severity")
                }
                style={{
                  border: errorFields.find((x) => x === "condition_severity")
                    ? "1px solid red"
                    : "1px solid #ced4da",
                }}
              >
                <option value="">{t("Select severity level")}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>

          {/* Field for Details about the Condition */}
          <div className="row mb-4">
            <div className="col-2 align-content-center">
              <p className="m-0">
                <strong>{t("Condition Details")}</strong>
                <span style={{ color: "red" }}> * </span>
              </p>
            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder={t("Add details about the condition")}
                value={individual.condition_details}
                onChange={(e) => handleStringInput(e, "condition_details")}
                style={{
                  border: errorFields.find((x) => x === "condition_details")
                    ? "1px solid red"
                    : "1px solid #ced4da",
                }}
              />
            </div>
          </div>
        </>
      ) : null}
      {/* Notes */}
      <div className="row mb-4">
        <div className="col-2 align-content-center">
          <p className="m-0">
            <strong>{t("Notes")}</strong>
          </p>
        </div>
        <div className="col-4">
          <textarea
            className="form-control"
            maxLength="225"
            rows="5"
            placeholder={t("Add Extra Info")}
            value={individual.notes}
            onChange={(e) => {
              updateServerParams(e.target.value, "notes");
            }}
          ></textarea>
        </div>
      </div>
      
      {/* is working */}
      <div className="row mb-4">
        <div className="col-2 align-content-center">
          <p className="m-0">
            <strong>{t("Is Working")}</strong>
          </p>
        </div>
        <div className="col-4">
          <div className="form-check form-switch">
            <input
              className={`form-check-input `}
              checked={individual.is_working}
              type="checkbox"
              role="switch"
              onChange={(e) => {
                updateServerParams(e.target.checked, "is_working");
              }}
              style={{
                border: errorFields.find((x) => x === "is_working")
                  ? "1px solid red"
                  : "1px solid #ced4da",
              }}
            />
          </div>
        </div>
      </div>

      {individual.is_working ? (
        <>
          <div className="row mb-4">
            <div className="col-2 align-content-center">
              <p className="m-0">
                <strong>{t("Job title")}</strong>
                <span style={{ color: "red" }}> * </span>
              </p>
            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder={t("Add job title")}
                value={individual.job_title}
                onChange={(e) => handleStringInput(e, "job_title")}
                style={{
                  border: errorFields.find((x) => x === "job_title")
                    ? "1px solid red"
                    : "1px solid #ced4da",
                }}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-2 align-content-center">
              <p className="m-0">
                <strong>{t("Salary")}</strong>
                <span style={{ color: "red" }}> * </span>
              </p>
            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder={t("Add salary")}
                type="number"
                inputMode="numeric"
                pattern="\d*"
                min="0"
                value={individual.salary}
                onChange={(e) => {
                  updateServerParams(e.target.value, "salary");
                }}
                style={{
                  border: errorFields.find((x) => x === "salary")
                    ? "1px solid red"
                    : "1px solid #ced4da",
                }}
              />
            </div>
          </div>{" "}
        </>
      ) : (
        <></>
      )}
      {/* Is Draft */}
      <div className="row mb-4">
        <div className="col-2 align-content-center">
          <p className="m-0">
            <strong>{t("Is draft")}</strong>
          </p>
        </div>
        <div className="col-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={individual.is_draft}
              onChange={(e) => {
                updateServerParams(e.target.checked, "is_draft");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
