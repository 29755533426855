import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import "./visitStyle.css";

// Family Tables
import FamilyReportTables from "components/family/FamilyCommon/FamilyReportTables";
import FamilyTables from "components/family/FamilyCommon/FamilyTables";
import { getFamily, handleEdit } from "pages/Utility/Functions/familyTables";
import {
  calculateAgeCategories,
  calculateConditionSeverities,
  fetchFamilyIndividuals,
  calculateAge,
} from "../../Utility/Functions/familyTables";

const GeneralInformation = ({
  family,
  setFamily,
  t,
  redCrescent,
  individuals,
  headOfFamily,
  rentAmount,
  isRedCrescent,
  setIsRedCrescent,
  income,
  totalBills,
  setRentAmount,
  visibleTables,
}) => {
  const [loading, setLoading] = useState(false);
  const [familyId, setFamilyId] = useState(null);
  const [waterBill, setWaterBill] = React.useState(null);
  const [gasBill, setGasBill] = React.useState(null);
  const [electricityBill, setElectricityBill] = React.useState(null);
  const [internetBill, setInternetBill] = React.useState(null);
  const [rent, setRent] = React.useState(null);
  const [emergencyButtonActive, setemergencyButtonActive] =
    React.useState(false);

  useEffect(() => {
    if (familyId) {
      getFamily(
        familyId,
        individuals,
        setFamily,
        setIsRedCrescent,
        setWaterBill,
        setGasBill,
        setElectricityBill,
        setInternetBill,
        setRentAmount,
        setemergencyButtonActive,
        setRent
      );
    }
  }, [familyId, individuals]);

  // Fetch family and individual data
  // useEffect(() => {
  //   fetchFamilyIndividuals(
  //     familyId,
  //     setIndividuals,
  //     setFamily,
  //     setHeadOfFamily,
  //     setRedCrescent,
  //     setIsRedCrescent,
  //     setIncome,
  //     setTotalBills,
  //     setRentAmount,
  //     setUnderTwo,
  //     setOverTwo,
  //     setAdults,
  //     setSeniors,
  //     setIllnessSeverity,
  //     setDisabilitySeverity,
  //     setLoading
  //   );
  // }, [familyId]);

  // Calculate illness and disability severities
  const [illnessSeverity, setIllnessSeverity] = useState(0);
  const [disabilitySeverity, setDisabilitySeverity] = useState(0);
  const [ illnessDisabilitySeverity , setIllnessDisabilitySeverity ] = useState(0);

  // Counters for age categories
  const [underTwo, setUnderTwo] = useState(0);
  const [overTwo, setOverTwo] = useState(0);
  const [adults, setAdults] = useState(0);
  const [seniors, setSeniors] = useState(0);
  useEffect(() => {
    if (individuals.length > 0) {
      calculateAgeCategories(
        individuals,
        setUnderTwo,
        setOverTwo,
        setAdults,
        setSeniors
      );
    }
  }, [familyId, individuals]);
  
  
  console.log( individuals, "=-=-=-=-=-=-");


  return (
    <div className="mb-3">
      {/* General information */}
      <Accordion defaultActiveKey={null} className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("General Information")}</Accordion.Header>
          <Accordion.Body>
            <FamilyReportTables
              id={familyId}
              individuals={individuals}
              headOfFamily={headOfFamily}
              rentAmount={rentAmount}
              isRedCrescent={isRedCrescent}
              redCrescent={redCrescent}
              income={income}
              totalBills={totalBills}
              setRentAmount={setRentAmount}
              visibleTables={["request"]}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Family Report Section */}
      <Accordion defaultActiveKey={null} className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("Family Report")}</Accordion.Header>
          <Accordion.Body>
            <FamilyReportTables
              individuals={individuals}
              headOfFamily={headOfFamily}

              calculateAge={calculateAge}
              visibleTables={["individuals"]}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div>
        {/* Past visitsSection */}
        <Accordion defaultActiveKey={null} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("Past Visits")}</Accordion.Header>
            <Accordion.Body>
              <FamilyReportTables
                individuals={individuals}
                headOfFamily={headOfFamily}
                visibleTables={["requestDetails"]}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div>
        {/* Past emergency */}
        <Accordion defaultActiveKey={null} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("Past Emergency")}</Accordion.Header>
            <Accordion.Body>
              <FamilyTables tableType="emergencies" id={family?.id} t={t} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default GeneralInformation;
