import React, { useState } from "react";
import Autocomplete from "../Common/Autocomplete";

export default function FamilyInformation({
    individual,
    selectedFamily,
    updateServerParams,
    isFormSubmitted,
    errorFields,
    relationship,
    setRelationship,
    customRelationship,
    setCustomRelationship,
    getPartnerOptions,
    t
}) {
    return (
        <>
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                        <strong>{t("Father")} </strong>
                    </p>
                </div>
                <div className="col-4 ">
                    <Autocomplete
                        name="Father"
                        searchParam="name"
                        placeholder={
                            individual?.father_name || t("Select a father")
                        }
                        searchApi={false}
                        list={selectedFamily?.individuals?.filter(
                            (x) => x.gender === "male" && x.id !== individual.id
                        )}
                        selectedObject={(value) => {
                            if (!value) {
                                updateServerParams(null, "father");
                                updateServerParams(null, "father_name");
                                updateServerParams(null, "father_partner_id"); // Reset partner_id
                            } else {
                                updateServerParams(value?.id, "father");
                                updateServerParams(
                                    value?.partner_id,
                                    "father_partner_id"
                                ); // Save partner_id for Mother
                            }
                        }}
                        isError={
                            isFormSubmitted && errorFields.includes("father")
                        }
                        hideOverlay={true}
                    />
                </div>
            </div>
            {/* Father | This field will not be shown in individual create case 
    {id === "new" ? (
    <></>
    ) : (*/}
            {/* Father | This field will not be shown in individual create case */}
            {/* <div className="row mb-4">
            <div className="col-2 align-content-center">
                <p className="m-0">
                <strong>
                    {t("Is the father outside the country?")}
                </strong>
                </p>
            </div>
            <div className="col-4 d-flex justify-content-center">
                <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="fatherLocation"
                    id="fatherOutside"
                    value="yes"
                    checked={isOutsideCountrySelectedFather === true}
                    onChange={() =>
                    setIsOutsideCountrySelectedFather(true)
                    }
                />
                <label
                    className="form-check-label"
                    htmlFor="fatherInside"
                >
                    {t("Yes")}
                </label>
                </div>
                <div className="form-check form-check-inline ms-3">
                <input
                    className="form-check-input"
                    type="radio"
                    name="fatherLocation"
                    id="fatherOutside"
                    value="no"
                    checked={isOutsideCountrySelectedFather === false}
                    onChange={() =>
                    setIsOutsideCountrySelectedFather(false)
                    }
                />
                <label
                    className="form-check-label"
                    htmlFor="fatherOutside"
                >
                    {t("No")}
                </label>
                </div>
            </div>
            </div>
            {isOutsideCountrySelectedFather === false && (
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                <p className="m-0">
                    <strong>{t("Father")}</strong>
                </p>
                </div>
                <div className="col-4">
                <Autocomplete
                    className="form-control"
                    name="Father"
                    searchParam="name"
                    placeholder={
                    individual?.father_name || t("Select a father")
                    }
                    list={selectedFamily?.individuals?.filter(
                    (x) => x.gender === "male"
                    )}
                    selectedObject={(value) => {
                    if (!value) {
                        updateServerParams(null, "father");
                        updateServerParams(null, "father_name");
                    } else {
                        updateServerParams(value?.id, "father");
                    }
                    }}
                    isError={
                    isFormSubmitted &&
                    errorFields.includes("father")
                    }
                />
                </div>
            </div>
            )}
            {isOutsideCountrySelectedFather === true && (
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                <p className="m-0">
                    <strong>{t("Full Name of the Father")}</strong>
                </p>
                </div>
                <div className="col-4">
                <input
                    className="form-control"
                    placeholder={t(
                    "Enter the full name of the father"
                    )}
                    value={individual.full_name_of_father || ""}
                    onChange={(e) =>
                    updateServerParams(
                        e.target.value,
                        "full_name_of_father"
                    )
                    }
                />
                </div>
            </div>
            )} */}
            {/* )} */}
            {/* Mother | This field will not be shown in individual create case */}
            {/* {id === "new" ? (
    <></>
    ) : ( */}
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                        <strong>{t("Mother")}</strong>
                    </p>
                </div>
                <div className="col-4 ">
                    <Autocomplete
                        name="Mother"
                        searchParam="name"
                        placeholder={
                            individual?.mother_name || t("Select a mother")
                        }
                        searchApi={false}
                        list={selectedFamily?.individuals?.filter(
                            (x) =>
                                x.gender === "female" &&
                                (!individual.father_partner_id ||
                                    x.id === individual.father_partner_id) && x.id !== individual.id
                        )}
                        selectedObject={(value) => {
                            if (!value) {
                                updateServerParams(null, "mother");
                                updateServerParams(null, "mother_name");
                                updateServerParams(null, "mother_partner_id"); // Reset partner_id
                            } else {
                                updateServerParams(value?.id, "mother");
                                updateServerParams(
                                    value?.partner_id,
                                    "mother_partner_id"
                                );
                            }
                        }}
                        isError={
                            isFormSubmitted && errorFields.includes("mother")
                        }
                        hideOverlay={true}
                    />
                </div>
            </div>
            {/* Mother | This field will not be shown in individual create case */}
            {/* <div className="row mb-4">
            <div className="col-2 align-content-center">
                <p className="m-0">
                <strong>
                    {t("Is the mother outside the country?")}
                </strong>
                </p>
            </div>
            <div className="col-4 d-flex justify-content-center">
                <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="motherLocation"
                    id="motherInside"
                    checked={
                    isOutsideCountrySelectedForMother === true
                    }
                    value="yes"
                    onChange={() =>
                    setIsOutsideCountrySelectedForMother(true)
                    }
                />
                <label
                    className="form-check-label"
                    htmlFor="motherInside"
                >
                    {t("Yes")}
                </label>
                </div>
                <div className="form-check form-check-inline ms-3">
                <input
                    className="form-check-input"
                    type="radio"
                    name="motherLocation"
                    id="motherOutside"
                    value="no"
                    checked={
                    isOutsideCountrySelectedForMother === false
                    }
                    onChange={() =>
                    setIsOutsideCountrySelectedForMother(false)
                    }
                />
                <label
                    className="form-check-label"
                    htmlFor="motherOutside"
                >
                    {t("No")}
                </label>
                </div>
            </div>
            </div>
            {!isOutsideCountrySelectedForMother ? (
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                <p className="m-0">
                    <strong>{t("Mother")}</strong>
                </p>
                </div>
                <div className="col-4">
                <Autocomplete
                    className="form-control"
                    name="Mother"
                    searchParam="name"
                    placeholder={
                    individual?.mother_name || t("Select a mother")
                    }
                    list={selectedFamily?.individuals?.filter(
                    (x) => x.gender === "female"
                    )}
                    selectedObject={(value) => {
                    if (!value) {
                        updateServerParams(null, "mother");
                        updateServerParams(null, "mother_name");
                    } else {
                        updateServerParams(value?.id, "mother");
                    }
                    }}
                    isError={
                    isFormSubmitted &&
                    errorFields.includes("mother")
                    }
                />
                </div>
            </div>
            ) : (
            <></>
            )}
            {isOutsideCountrySelectedForMother && (
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                <p className="m-0">
                    <strong>{t("Full Name of the Mother")}</strong>
                </p>
                </div>
                <div className="col-4">
                <input
                    className="form-control"
                    placeholder={t(
                    "Enter the full name of the mother"
                    )}
                    value={individual.full_name_of_mother || ""}
                    onChange={(e) =>
                    updateServerParams(
                        e.target.value,
                        "full_name_of_mother"
                    )
                    }
                />
                </div>
            </div>
            )}

            */}
            {/* )} */}
            {/* Relationship */}
            {/* <div className="row mb-4">
            <div className="col-2 align-content-center">
                <p className="m-0">
                <strong>{t("Relationship")}</strong>
                </p>
            </div>
            <div className="col-4">
                <select
                className="form-control form-select"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
                style={{
                    border: errorFields.find(
                    (x) => x === "relationship"
                    )
                    ? "1px solid red"
                    : "1px solid #ced4da",
                }}
                >
                <option value="">{t("Select Relationship")}</option>
                <option value="husband">{t("Husband")}</option>
                <option value="wife">{t("Wife")}</option>
                <option value="mother">{t("Mother")}</option>
                <option value="father">{t("Father")}</option>
                <option value="brother">{t("Brother")}</option>
                <option value="son">{t("Son")}</option>
                <option value="daughter">{t("Daughter")}</option>
                <option value="custom">{t("Custom")}</option>
                </select>
            </div>
            </div>

            {relationship === "custom" && (
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                <p className="m-0">
                    <strong>{t("Add Custom Relationship")}</strong>
                </p>
                </div>
                <div className="col-4">
                <input
                    className="form-control"
                    placeholder={t("Enter Custom Relationship")}
                    value={customRelationship}
                    onChange={(e) =>
                    setCustomRelationship(e.target.value)
                    }
                    style={{
                    border: errorFields.find(
                        (x) => x === "customRelationship"
                    )
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                />
                </div>
            </div>
            )} */}
            {/* Partner relation status */}
            <div className="row mb-4">
                <div className="col-2 align-content-center">
                    <p className="m-0">
                        <strong>{t("Partner relationship")}</strong>
                        <span style={{ color: "red" }}> * </span>
                    </p>
                </div>
                <div className="col-4">
                    <select
                        className={`form-control form-select ${isFormSubmitted && !individual.gender ? "" : ""
                            }`}
                        value={individual.partner_relationship}
                        onChange={(e) => {
                            updateServerParams(
                                e.target.value,
                                "partner_relationship"
                            );
                        }}
                        style={{
                            border: errorFields.find(
                                (x) => x === "partner_relationship"
                            )
                                ? "1px solid red"
                                : "1px solid #ced4da",
                        }}
                    >
                        <option>{t("Select a status")}</option>
                        <option value="single">{t("Single")}</option>
                        <option value="married">{t("Married")}</option>
                        <option value="divorced">{t("Divorced")}</option>
                        <option value="widow">{t("Widow")}</option>
                        <option value="engage">{t("Engage")}</option>
                        {/* TODO: add partnership status: good, tense, good tense */}
                        {/* <option value="good">Good</option>
        <option value="tense">Tense</option>
        <option value="good_tense">Good Tense</option> */}
                        <option value="extra_marital">
                            {t("Extra Marital")}l
                        </option>
                        <option value="separation">
                            {t("Separation")}
                        </option>
                    </select>
                </div>
            </div>
            {["married", "engage", "widow", "divorced"].includes(
                individual.partner_relationship
            ) ? (
                <>
                    <div className="row mb-4">
                        <div className="col-2 align-content-center">
                            <p className="m-0">
                                <strong>
                                    {t("Partner relationship status")}
                                </strong>
                            </p>
                        </div>
                        <div className="col-4">
                            <select
                                className="form-control form-select"
                                value={individual.partner_relationship_status}
                                onChange={(e) => {
                                    updateServerParams(
                                        e.target.value,
                                        "partner_relationship_status"
                                    );
                                }}
                            >
                                <option>{t("Select a status")}</option>
                                <option value="good">{t("Good")}</option>
                                <option value="tense">{t("Tense")}</option>
                                <option value="good_tense">
                                    {t("Good Tense")}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-2 align-content-center">
                            <p className="m-0">
                                <strong>{t("Partner")}</strong>
                            </p>
                        </div>
                        <div className="col-4 ">
                            <Autocomplete
                                name={t("Partner")}
                                searchParam="name"
                                placeholder={
                                    individual.partner_name ||
                                    t("Select a partner")
                                }
                                searchApi={false}
                                // placeholder={individual.partner_name || t("Select a partner")}
                                list={getPartnerOptions()}
                                selectedObject={(value) => {
                                    if (!value) {
                                        updateServerParams(null, "partner_name");
                                        updateServerParams(null, "partner_id");
                                    } else {
                                        updateServerParams(value?.id, "partner_id");
                                    }
                                }}
                                style={{
                                    border: errorFields.find(
                                        (x) => x === "partner_id"
                                    )
                                        ? "1px solid red"
                                        : "1px solid #ced4da",
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}